import './ResetPassword.scss'

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  clearUsersError,
  resetPassword,
} from '../../../../actions/usersActions'

export default function ResetPassword() {
  const dispatch = useDispatch()
  const usersState = useSelector(state => state.users)
  const { error } = usersState

  const params = new URLSearchParams(window.location.search)
  const email = params.get('email')
  const passwordToken = params.get('passwordToken')

  const [createPassword, setCreatePassword] = useState({
    newPassword: '',
    repeatNewPassword: '',
    error: '',
  })

  // error handling
  if (usersState.error) {
    // TODO: show a nice pop-up
    alert(usersState.error)
    dispatch(clearUsersError())
  }

  const handleNewPassword = e => {
    const { value, name } = e.target
    setCreatePassword({ ...createPassword, [name]: value })
  }

  const handleResetPassword = () => {
    const { newPassword, repeatNewPassword } = createPassword
    const credentials = {
      email,
      passwordToken,
      newPassword,
    }

    if (newPassword && newPassword === repeatNewPassword) {
      dispatch(resetPassword(credentials))
      setCreatePassword({
        ...createPassword,
        error: '',
      })
    } else if (!newPassword && !repeatNewPassword) {
      setCreatePassword({
        ...createPassword,
        error: 'Please type the new password',
      })
    } else {
      setCreatePassword({
        ...createPassword,
        error: 'Password does not match confirm password',
      })
    }
  }

  const returnError = () => {
    let resetPasswordError
    if (createPassword.error) {
      resetPasswordError = createPassword.error
    } else if (error) {
      resetPasswordError = error
    }
    if (resetPasswordError) {
      return <h6 className='error'>{resetPasswordError}!</h6>
    }
  }

  return (
    <div>
      <h3 id='reset-password-text'>Reset Password for {email}</h3>
      <input
        type='password'
        name='newPassword'
        placeholder='New Password'
        aria-label='New Password'
        className='form-control text-input password-input'
        onChange={handleNewPassword}
      />
      <input
        type='password'
        name='repeatNewPassword'
        placeholder='Repeat New Password'
        aria-label='Repeat New Password'
        className='form-control text-input password-input'
        onChange={handleNewPassword}
      />
      {returnError()}
      <button
        type='button'
        className='btn'
        id='btn-reset-password'
        onClick={handleResetPassword}>
        <b>Reset Password</b>
      </button>
    </div>
  )
}
