import './DeleteModal.scss'

import React from 'react'

export default function DeleteModal(props) {
  const error = props.error
  const element = props.element
  const setOpenModal = props.setOpenModal
  const proceedDelete = props.proceedDelete

  return (
    <>
      <div id='modal-container'>
        <img src='images/trash_normal.svg' alt='delete'></img>

        <div id='modal-info'>
          {error ? (
            <p id='delete-title'>{error}</p>
          ) : (
            <>
              <p id='delete-title'>
                Are you sure you want to delete this {element}?
              </p>
              <p id='delete-description'>This action is irreversible!</p>
            </>
          )}
        </div>

        <div>
          <div
            className='button-modal'
            id='candel-btn-modal'
            onClick={() => setOpenModal(false)}>
            <span>Cancel</span>
          </div>

          {!error && (
            <div
              className='button-modal'
              id='proceed-btn-modal'
              onClick={() => proceedDelete()}>
              <span>Yes, delete</span>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
