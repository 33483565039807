import * as actions from '../actionTypes/usersActionTypes'

let initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_USERS_ERROR:
      return {
        ...state,
        error: action.error,
        currentUser: null,
      }

    case actions.CLEAR_USERS_ERROR:
      return {
        ...state,
        error: null,
      }

    case actions.CURRENT_USER_RECEIVED:
      return {
        ...state,
        currentUser: action.currentUser,
      }

    case actions.CURRENT_USER_ROLES_RECEIVED:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          _isAdmin: action.currentUserRoles.includes('Administrator'),
          _isBusinessOwner: action.currentUserRoles.includes('BusinessOwner'),
          _isBusinessManager:
            action.currentUserRoles.includes('BusinessManager'),
          _isBetaTester: action.currentUserRoles.includes('BetaTester'),
        },
      }

    case actions.FORGOT_PASSWORD_EMAIL_SENT:
      return {
        ...state,
        forgotPasswordEmailSent: action.emailSent,
      }

    case actions.CONTACT_US_EMAIL_SENT:
      return {
        ...state,
        contactUsEmailSent: action.emailSent,
      }

    default:
      return state
  }
}
