import './Map.scss'

import MarkerClusterer from '@googlemaps/markerclustererplus'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getPinsByLocation, updateShownPins } from '../../actions/pinsActions'
import common from '../../common'
import safePick from '../../utils/safePick'
import createHtmlMapMarker from './HtmlMapMarker.js'
import mapStyle from './mapStyle'

export default function Map(props) {
  const dispatch = useDispatch()

  const minZoomLevel = 7
  const maxZoomLevel = 17
  const takePins = 100
  const defaultInitialLatitude = 46.769059
  const defaultInitialLongitude = 23.590812

  const mapPins = useSelector(state => state.pins.mapPins)
  const newMapPins = useSelector(state => state.pins.newMapPins)
  const mapPinsRef = useRef(mapPins)
  mapPinsRef.current = mapPins

  const [map, setMap] = useState()
  const [clusterer, setClusterer] = useState()

  const { userMenuOpen, setUerMenuOpenStatus, openClosePinsPage } = props
  const userMenuOpenRef = useRef(userMenuOpen)
  const setUerMenuOpenStatusRef = useRef(setUerMenuOpenStatus)
  const openClosePinsPageRef = useRef(openClosePinsPage)
  userMenuOpenRef.current = userMenuOpen

  const takePinsRadius = useCallback(zoom => {
    const fetchRangesByZoom = [
      { zoom: 7, range: 577790 },
      { zoom: 8, range: 288895 },
      { zoom: 9, range: 144447 },
      { zoom: 10, range: 72223 },
      { zoom: 11, range: 36111 },
      { zoom: 12, range: 18055 },
      { zoom: 13, range: 9027 },
      { zoom: 14, range: 4513 },
      { zoom: 15, range: 2256 },
      { zoom: 16, range: 1128 },
      { zoom: 17, range: 564 },
    ]
    const fetchRange = fetchRangesByZoom.find(x => x.zoom === zoom)
    return (fetchRange && fetchRange.range) || fetchRangesByZoom[0].range
  }, [])

  const getFormattedMapPins = () =>
    (newMapPins &&
      newMapPins.map(pin => {
        return {
          id: pin.ID,
          latitude: pin.Location.Latitude,
          longitude: pin.Location.Longitude,
          type: 'default',
          firstImageUrl: safePick(pin, 'Images', [0], 'URL'),
        }
      })) ||
    []

  const setMapCenter = useCallback(
    (map, latitude, longitude) =>
      map.setCenter(new window.google.maps.LatLng(latitude, longitude)),
    []
  )

  const addPinsToMap = () => {
    getFormattedMapPins().forEach(pin => {
      let latLngSet = new window.google.maps.LatLng(pin.latitude, pin.longitude)
      let pinIcon = pin.firstImageUrl
        ? `${common.imagesBaseUrl}/${pin.firstImageUrl}`
        : 'images/pinapp-logo.svg' // video

      let marker = createHtmlMapMarker({
        latlng: latLngSet,
        map: map,
        html: `<div class="mapMarker">
                <span><img src="${pinIcon}" /></span>
               </div>`,
      })

      // force clustering
      if (map) {
        setMapCenter(map, map.getCenter().lat(), map.getCenter().lng())
      }

      // pin clicked event
      marker.addListener('click', () => {
        const rawPin = mapPins.find(x => x.ID === pin.id)
        showClusterPins([rawPin])
      })

      clusterer.addMarker(marker, true)
    })
  }

  const mapDraged = useCallback(
    map => {
      const mapCenter = {
        lat: map.getCenter().lat(),
        lng: map.getCenter().lng(),
      }

      dispatch(
        getPinsByLocation(
          mapCenter.lat,
          mapCenter.lng,
          takePins,
          takePinsRadius(map.zoom)
        )
      )
    },
    [dispatch, takePinsRadius]
  )

  const closeUserMenuIfOpen = () => {
    if (userMenuOpenRef.current) {
      setUerMenuOpenStatusRef.current(false)
    }
  }

  const showClusterPins = useCallback(
    pins => {
      const leftPage = document.getElementById('first-page')
      if (leftPage) leftPage.scrollTop = 0

      openClosePinsPageRef.current(true)
      dispatch(updateShownPins(pins))
    },
    [dispatch]
  )

  addPinsToMap()

  useEffect(() => {
    const mapControls = {
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
    }

    const mapConfig = {
      center: { lat: defaultInitialLatitude, lng: defaultInitialLongitude },
      zoom: 14,
      minZoom: minZoomLevel,
      maxZoom: maxZoomLevel,
      scrollwheel: true,
      styles: mapStyle.WHITE,
      ...mapControls,
    }

    const smallMarkerStyle = {
      width: 23,
      height: 33,
      textSize: 11,
    }

    const bigMarkerStyle = {
      width: 48,
      height: 53,
      textSize: 10,
    }

    const clusterOptions = {
      zoomOnClick: false,
      styles: [
        // small
        {
          ...smallMarkerStyle,
          url: 'images/map/marker_1.svg',
        },
        // medium
        {
          ...bigMarkerStyle,
          url: 'images/map/marker_10.svg',
        },
        // large
        {
          ...bigMarkerStyle,
          url: 'images/map/marker_100.svg',
        },
        // xl
        {
          ...bigMarkerStyle,
          url: 'images/map/marker_100.svg',
        },
        // max
        {
          ...bigMarkerStyle,
          url: 'images/map/marker_100.svg',
        },
      ],
    }

    const initMap = () => {
      // add map
      let mapContainer = document.getElementById('map')
      let map = new window.google.maps.Map(mapContainer, mapConfig)
      setMap(map)

      const locationReceived = position => {
        setMapCenter(map, position.coords.latitude, position.coords.longitude)
        dispatch(
          getPinsByLocation(
            position.coords.latitude,
            position.coords.longitude,
            takePins,
            takePinsRadius(map.zoom)
          )
        )
      }

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(locationReceived)
      }

      // add cluster
      const clusterer = new MarkerClusterer(map, [], clusterOptions)
      setClusterer(clusterer)

      // add search box
      let mapSearchBox = document.getElementById('map-search-box')
      let searchBox = new window.google.maps.places.SearchBox(mapSearchBox)
      map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(
        mapSearchBox
      )
      searchBox.addListener('places_changed', function () {
        const place = searchBox.getPlaces()[0]

        let bounds = new window.google.maps.LatLngBounds()

        if (!place.geometry) {
          // Returned place contains no geometry
          return
        }

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport)
        } else {
          bounds.extend(place.geometry.location)
        }

        map.fitBounds(bounds)
      })

      // map dragged event
      window.google.maps.event.addListener(map, 'dragend', event => {
        closeUserMenuIfOpen()
        mapDraged(map, event)
      })

      // map clicked event
      window.google.maps.event.addListener(map, 'click', event => {
        closeUserMenuIfOpen()
        // disable points of interest
        if (event.placeId) {
          event.stop()
        }
      })

      // map zoom changed event
      window.google.maps.event.addListener(map, 'zoom_changed', event => {
        closeUserMenuIfOpen()
        // get current map center
        const mapCenter = {
          lat: map.getCenter().lat(),
          lng: map.getCenter().lng(),
        }

        // fech new pins
        dispatch(
          getPinsByLocation(
            mapCenter.lat,
            mapCenter.lng,
            takePins,
            takePinsRadius(map.zoom)
          )
        )
      })

      // cluster clicked event
      window.google.maps.event.addListener(
        clusterer,
        'clusterclick',
        function (cluster) {
          let markers = cluster.getMarkers()
          let clusterPins = mapPinsRef.current.filter(x =>
            markers.find(
              y =>
                y.latlng.lat() === x.Location.Latitude &&
                y.latlng.lng() === x.Location.Longitude
            )
          )
          showClusterPins(clusterPins)
        }
      )
    }

    const requestPinsByLocation = async () => {
      await dispatch(
        getPinsByLocation(
          mapConfig.center.lat,
          mapConfig.center.lng,
          takePins,
          takePinsRadius(mapConfig.zoom)
        )
      )
    }

    initMap()
    requestPinsByLocation()
  }, [dispatch, mapDraged, takePinsRadius, showClusterPins, setMapCenter])

  return (
    <>
      <div id='map'></div>
    </>
  )
}
