import './Location.scss'

import React from 'react'

import common from '../../common'

export default function Location(props) {
  let location = props.location
  let clickEvent = props.customClickEvent
  let currentLocationId = props.currentLocationId

  return (
    <div className='location-wrapper'>
      <div
        id={
          location.ID === currentLocationId
            ? 'row-location-list-selected'
            : 'row-location-list'
        }
        onClick={e => clickEvent(location)}>
        {/* <div id='location-list-type'>
          <img
            className='business-type-logo'
            src='images/business-pins-icons/pharmacy.png'
            alt=''></img>
        </div> */}
        <div id='location-picture-list'>
          {location.Images[0] && (
            <img
              id='location-picture'
              src={`${common.imagesBaseUrl}/${
                location.Images.find(i => i.Position === 0).URL
              }`}
              alt='logo'></img>
          )}
        </div>
        <div id='location-info-list'>
          <div id='location-name-list'>{location.Name}</div>
          <div id='location-address-list'>{location.LocationName}</div>
        </div>
      </div>
    </div>
  )
}
