import './BusinessDetails.scss'

import ImageZoom from '@moorecp/react-medium-image-zoom'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal } from 'react-responsive-modal'

import { deleteBusiness } from '../../actions/businessesActions'
import common from '../../common'
import DeleteModal from '../DeleteModal/DeleteModal'

export default function BusinessDetails(props) {
  let business = props.business
  const handleSecondPageContent = props.handleSecondPageContent
  const [openModal, setOpenModal] = useState(false)
  const dispatch = useDispatch()

  const editClicked = () => {
    handleSecondPageContent('EditBusiness')
  }

  const deleteClicked = () => {
    setOpenModal(true)
  }

  const proceedDeleteBusiness = () => {
    dispatch(deleteBusiness(business.Id, handleSecondPageContent))
    setOpenModal(false)
  }

  return (
    <>
      <div id='business-details-top'>{business.Name}</div>

      <div id='business-details-body'>
        <div id='logo-wrapper'>
          {business.LogoUrl ? (
            <ImageZoom
              image={{
                src: `${common.imagesBaseUrl}/${business.LogoUrl}`,
                className: 'business-logo',
              }}
              zoomImage={{
                src: `${common.imagesBaseUrl}/${business.LogoUrl}`,
              }}
            />
          ) : (
            <img
              className='business-logo'
              src={'images/default_business_logo.svg'}
              alt='logo'></img>
          )}
        </div>
        <div className='page-row'>
          <div className='row-left-column'>Business name:</div>
          <div className='row-right-column'>{business.Name}</div>
        </div>
        <div className='page-row'>
          <div className='row-left-column'>Address:</div>
          <div className='row-right-column'>{business.Address}</div>
        </div>
        <div className='page-row'>
          <div className='row-left-column'>Administrator:</div>
          <div className='row-right-column'>{business.Administrator}</div>
        </div>
        <div className='page-row'>
          <div className='row-left-column'>Registration code:</div>
          <div className='row-right-column'>{business.Cui}</div>
        </div>
        <div className='page-row'>
          <div className='row-left-column'>Phone number:</div>
          <div className='row-right-column'>{business.PhoneNumber}</div>
        </div>
      </div>

      <div id='business-details-bottom'>
        <div id='delete-business-button' onClick={deleteClicked}>
          Delete
        </div>
        <div id='edit-business-button' onClick={editClicked}>
          Edit
        </div>
      </div>

      <Modal open={openModal} onClose={() => setOpenModal(false)} center>
        <DeleteModal
          element='business'
          setOpenModal={setOpenModal}
          proceedDelete={proceedDeleteBusiness}
        />
      </Modal>
    </>
  )
}
