import { composeWithDevTools } from '@redux-devtools/extension'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'

import App from './App'
import rootReducer from './reducers/rootReducer'
import * as serviceWorker from './serviceWorker'

const store = createStore(
  rootReducer(),
  composeWithDevTools(applyMiddleware(thunk))
)

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()
