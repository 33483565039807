import './App.scss'

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

import { getCurrentUser, getCurrentUserRoles } from './actions/usersActions'
import FullScreen from './components/FullScreen/FullScreen'
import Home from './components/Home/Home'
import Welcome from './components/Welcome/Welcome'

export default function App() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const currentUser = useSelector(state => state.users.currentUser)

  const haveToken = () =>
    localStorage.getItem('TokenType') && localStorage.getItem('AccessToken')

  useEffect(() => {
    const requestCurrentUser = async () => {
      setLoading(true)
      await dispatch(getCurrentUser())
      await dispatch(getCurrentUserRoles())
      setLoading(false)
    }

    if (haveToken()) {
      requestCurrentUser()
    }
  }, [dispatch])

  if (loading || (haveToken() && !currentUser)) {
    // Loading
    return <></>
  } else if (currentUser) {
    return (
      // Authenticated
      <BrowserRouter>
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/privacy-policy' component={Home} />
          <Route path='/user-privacy-policy' component={Home} /> {/*FB*/}
          <Route render={() => <Redirect to={{ pathname: '/' }} />} />
        </Switch>
      </BrowserRouter>
    )
  } else {
    return (
      // Not authenticated
      <BrowserRouter>
        <Switch>
          <Route path='/' exact component={Welcome} />
          <Route path='/login' component={Welcome} />
          <Route path='/signup' component={Welcome} />
          <Route path='/business-signup' component={Welcome} />
          <Route path='/forgot-password' component={Welcome} />
          <Route path='/reset-password' component={Welcome} />
          <Route path='/contact-us' component={Welcome} />
          <Route path='/privacy-policy' component={Welcome} />
          <Route path='/business-privacy-policy' component={Welcome} />
          <Route path='/user-privacy-policy' component={FullScreen} /> {/*FB*/}
          <Route render={() => <Redirect to={{ pathname: '/' }} />} />
        </Switch>
      </BrowserRouter>
    )
  }
}
