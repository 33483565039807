const open = [
  {
    DayOfWeek: 0,
    TimeOpening: '08:00',
    TimeClosing: '18:00',
  },
  {
    DayOfWeek: 1,
    TimeOpening: '08:00',
    TimeClosing: '18:00',
  },
  {
    DayOfWeek: 2,
    TimeOpening: '08:00',
    TimeClosing: '18:00',
  },
  {
    DayOfWeek: 3,
    TimeOpening: '08:00',
    TimeClosing: '18:00',
  },
  {
    DayOfWeek: 4,
    TimeOpening: '08:00',
    TimeClosing: '18:00',
  },
  {
    DayOfWeek: 5,
    TimeOpening: '08:00',
    TimeClosing: '14:00',
  },
  {
    DayOfWeek: 6,
  },
]

const open24h = [
  {
    DayOfWeek: 0,
    TimeClosing: '00:00',
    TimeOpening: '00:00',
  },
  {
    DayOfWeek: 1,
    TimeClosing: '00:00',
    TimeOpening: '00:00',
  },
  {
    DayOfWeek: 2,
    TimeClosing: '00:00',
    TimeOpening: '00:00',
  },
  {
    DayOfWeek: 3,
    TimeClosing: '00:00',
    TimeOpening: '00:00',
  },
  {
    DayOfWeek: 4,
    TimeClosing: '00:00',
    TimeOpening: '00:00',
  },
  {
    DayOfWeek: 5,
    TimeClosing: '00:00',
    TimeOpening: '00:00',
  },
  {
    DayOfWeek: 6,
    TimeClosing: '00:00',
    TimeOpening: '00:00',
  },
]

const closed = [
  {
    DayOfWeek: 0,
  },
  {
    DayOfWeek: 1,
  },
  {
    DayOfWeek: 2,
  },
  {
    DayOfWeek: 3,
  },
  {
    DayOfWeek: 4,
  },
  {
    DayOfWeek: 5,
  },
  {
    DayOfWeek: 6,
  },
]

const openHoursOptions = [
  { Id: 1, Name: '00:00' },
  { Id: 2, Name: '07:00' },
  { Id: 3, Name: '07:30' },
  { Id: 4, Name: '08:00' },
  { Id: 5, Name: '08:30' },
  { Id: 6, Name: '09:00' },
  { Id: 7, Name: '09:30' },
  { Id: 8, Name: '10:00' },
  { Id: 9, Name: '10:30' },
  { Id: 10, Name: '11:00' },
  { Id: 11, Name: '11:30' },
  { Id: 12, Name: '12:00' },
  { Id: 13, Name: '12:30' },
  { Id: 14, Name: '13:00' },
  { Id: 15, Name: '13:30' },
  { Id: 16, Name: '14:00' },
  { Id: 17, Name: '14:30' },
  { Id: 18, Name: '15:00' },
  { Id: 19, Name: '15:30' },
  { Id: 20, Name: '16:00' },
  { Id: 21, Name: '16:30' },
  { Id: 22, Name: '17:00' },
  { Id: 23, Name: '17:30' },
  { Id: 24, Name: '18:00' },
  { Id: 25, Name: '18:30' },
  { Id: 26, Name: '19:00' },
  { Id: 27, Name: '19:30' },
  { Id: 28, Name: '20:00' },
  { Id: 29, Name: '20:30' },
  { Id: 30, Name: '21:00' },
  { Id: 31, Name: '21:30' },
  { Id: 32, Name: '22:00' },
  { Id: 33, Name: '00:00' },
]

export default {
  open,
  open24h,
  closed,
  openHoursOptions,
}
