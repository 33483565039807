import './Home.scss'

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'react-responsive-modal'
import { useHistory } from 'react-router-dom'

import { updateShownBusinesses } from '../../actions/businessesActions'
import { updateShownChallenges } from '../../actions/challengesActions'
import { updateShownLocations } from '../../actions/locationsActions'
import { updateShownPins } from '../../actions/pinsActions'
import { logOut } from '../../actions/usersActions'
import common from '../../common'
import AddBusiness from '../AddBusiness/AddBusiness'
import AddChallenge from '../AddChallenge/AddChallenge'
import AddChallengeTask from '../AddChallengeTask/AddChallengeTask'
import AddLocation from '../AddLocation/AddLocation'
import Approvals from '../Approvals/Approvals'
import OngoingChallenges from '../Approvals/OngoingChallenges'
import BusinessDetails from '../BusinessDetails/BusinessDetails'
import Businesses from '../Businesses/Businesses'
import ChallengeDetails from '../ChallengeDetails/ChallengeDetails'
import Challenges from '../Challenges/Challenges'
import ChallengeTask from '../ChallengeTask/ChallengeTask'
import ContactUs from '../ContactUs/ContactUs'
import LocationDetails from '../LocationDetails/LocationDetails'
import Locations from '../Locations/Locations'
import Map from '../Map/Map'
import MenuPage from '../MenuPage/MenuPage'
import OpenHours from '../OpenHours/OpenHours'
import PinsContent from '../Pins/Pins'
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy'
import Prizes from '../Prizes/Prizes'

export default function Home() {
  const dispatch = useDispatch()
  const history = useHistory()
  const currentUser = useSelector(state => state.users.currentUser)
  const mapPins = useSelector(state => state.pins.mapPins)
  const shownPins = useSelector(state => state.pins.shownPins)
  const businesses = useSelector(state => state.businesses.businesses)
  const selectedBusiness = useSelector(
    state => state.businesses.currentBusiness
  )
  const selectedLocation = useSelector(state => state.locations.currentLocation)
  const selectedChallenge = useSelector(
    state => state.challenges.currentChallenge
  )
  const locations = useSelector(state => state.locations.locations)
  const challenges = useSelector(state => state.challenges.challenges)

  const [modalPageContent, setModalPageContent] = useState()
  const [firstPageContent, setFirstPageContent] = useState()
  const [secondPageContent, setSecondPageContent] = useState()
  const [thirdPageContent, setThirdPageContent] = useState()

  const [userMenuOpen, setUerMenuOpen] = useState(false)
  const [menuPageOpen, setMenuPageOpen] = useState(true)
  const [firstPageOpen, setFirstPageOpen] = useState(false)
  const [secondPageOpen, setSecondPageOpen] = useState(false)
  const [thirdPageOpen, setThirdPageOpen] = useState(false)
  const [clickedMenuOption, setClickedMenuOption] = useState()

  const setUerMenuOpenStatus = status => {
    setUerMenuOpen(status)
  }

  const togglePages = () => {
    if (menuPageOpen) {
      setMenuPageOpen(false)
      setClickedMenuOption(null)
      setFirstPageOpen(false)
      setSecondPageOpen(false)
      setThirdPageOpen(false)
    } else {
      setMenuPageOpen(true)
    }
  }

  const handleFirstPageContent = pageContent => {
    const closePageAndDependents = () => {
      setFirstPageOpen(false)
      setSecondPageOpen(false)
      setThirdPageOpen(false)
      setClickedMenuOption(null)
    }

    if (!pageContent) {
      closePageAndDependents()
      return
    }

    if (firstPageOpen && firstPageContent === pageContent) {
      closePageAndDependents()
    } else {
      // set clicked menu
      setClickedMenuOption(pageContent)
      // close dependents
      setSecondPageOpen(false)
      setThirdPageOpen(false)
      // update page
      setFirstPageContent(pageContent)
      setMenuPageOpen(true)
      setFirstPageOpen(true)
    }
  }

  const handleSecondPageContent = (pageContent, keepPageOpen) => {
    const closePageAndDependents = () => {
      setSecondPageOpen(false)
      setThirdPageOpen(false)
    }

    if (!pageContent) {
      closePageAndDependents()
      return
    }

    if (!keepPageOpen && secondPageOpen && secondPageContent === pageContent) {
      closePageAndDependents()
    } else {
      // close dependents
      setThirdPageOpen(false)
      // update page
      setSecondPageContent(pageContent)
      setSecondPageOpen(true)
    }
  }

  const handleThirdPageContent = (pageContent, keepPageOpen) => {
    const closePageAndDependents = () => {
      setThirdPageOpen(false)
    }

    if (!pageContent) {
      closePageAndDependents()
      return
    }

    if (!keepPageOpen && thirdPageOpen && thirdPageContent === pageContent) {
      closePageAndDependents()
    } else {
      // update page
      setThirdPageContent(pageContent)
      setThirdPageOpen(true)
    }
  }

  const showPinsPage = status => {
    if (status) {
      // open
      handleFirstPageContent('Pins')
    } else {
      // close
      setFirstPageOpen(false)
    }
  }

  const handleLogOut = () => {
    dispatch(logOut)
    history.push('/')
    window.location.reload(false)
  }

  const clearSearchInput = () => {
    let mapSearchBox = document.getElementById('map-search-box')
    mapSearchBox.value = ''
  }

  const renderFirstPageContent = () => {
    switch (firstPageContent) {
      case 'Pins':
        if (mapPins?.length > 0 && (!shownPins || shownPins.length === 0)) {
          dispatch(updateShownPins(mapPins.slice(0, 25)))
        }
        return <PinsContent pins={shownPins} />
      case 'Businesses':
        if (!businesses || businesses.length === 0) {
          dispatch(updateShownBusinesses())
        }
        return (
          <Businesses
            handleSecondPageContent={handleSecondPageContent}
            businesses={businesses[0]}
          />
        )
      case 'Locations':
        if (!locations?.length) {
          dispatch(updateShownLocations())
        }
        return (
          <Locations
            handleSecondPageContent={handleSecondPageContent}
            locations={locations[0]}
          />
        )
      case 'Challenges':
        return (
          <Challenges
            handleSecondPageContent={handleSecondPageContent}
            challenges={challenges[0]}
          />
        )
      case 'Approvals':
        return (
          <OngoingChallenges
            handleSecondPageContent={handleSecondPageContent}
            challenges={challenges[0]}
          />
        )
      case 'Prizes':
        return <Prizes />
      default:
        return <></>
    }
  }

  const renderSecondPageContent = () => {
    switch (secondPageContent) {
      ////////// Businesses //////////
      case 'AddBusiness':
        return <AddBusiness handleSecondPageContent={handleSecondPageContent} />
      case 'EditBusiness':
        return (
          <AddBusiness
            business={selectedBusiness}
            handleSecondPageContent={handleSecondPageContent}
          />
        )
      case 'BusinessDetails':
        return (
          <BusinessDetails
            business={selectedBusiness}
            handleSecondPageContent={handleSecondPageContent}
          />
        )

      ////////// Locations //////////
      case 'AddLocation':
        return (
          <AddLocation
            handleSecondPageContent={handleSecondPageContent}
            handleThirdPageContent={handleThirdPageContent}
          />
        )
      case 'EditLocation':
        return (
          <AddLocation
            location={selectedLocation}
            handleSecondPageContent={handleSecondPageContent}
            handleThirdPageContent={handleThirdPageContent}
          />
        )
      case 'LocationDetails':
        return (
          <LocationDetails
            location={selectedLocation}
            handleSecondPageContent={handleSecondPageContent}
          />
        )

      ////////// Challenges //////////
      case 'AddChallenge':
        return (
          <AddChallenge
            handleSecondPageContent={handleSecondPageContent}
            handleThirdPageContent={handleThirdPageContent}
          />
        )
      case 'EditChallenge':
        return (
          <AddChallenge
            challenge={selectedChallenge}
            handleSecondPageContent={handleSecondPageContent}
            handleThirdPageContent={handleThirdPageContent}
          />
        )
      case 'ChallengeDetails':
        return (
          <ChallengeDetails
            challenge={selectedChallenge}
            handleSecondPageContent={handleSecondPageContent}
            handleThirdPageContent={handleThirdPageContent}
          />
        )

      ////////// Approvals //////////
      case 'Approvals':
        return (
          <Approvals
            challenge={selectedChallenge}
            handleThirdPageContent={handleThirdPageContent}
          />
        )

      default:
        return <></>
    }
  }

  const renderThirdPageContent = () => {
    ////////// Challenge Tasks //////////
    switch (thirdPageContent) {
      case 'ChallengeTask':
        return <ChallengeTask />
      case 'AddChallengeTask':
        return (
          <AddChallengeTask handleThirdPageContent={handleThirdPageContent} />
        )

      ////////// Open Hours //////////
      case 'OpenHours':
        return <OpenHours handleThirdPageContent={handleThirdPageContent} />

      ////////// User Submits //////////
      case 'UserSubmits':
        return (
          <Approvals
            forThirdPage={true}
            challenge={selectedChallenge}
            handleThirdPageContent={handleThirdPageContent}
          />
        )
      default:
        return <></>
    }
  }

  const renderModalPage = () => {
    ////////// PrivacyPolicy & ContactUs //////////
    switch (modalPageContent) {
      case 'PrivacyPolicy':
        return <PrivacyPolicy />
      case 'ContactUs':
        return <ContactUs />
      default:
        return <></>
    }
  }

  useEffect(() => {
    const path = window.location.pathname
    if (path === '/privacy-policy' || path === '/user-privacy-policy') {
      setModalPageContent('PrivacyPolicy')
    }

    dispatch(updateShownBusinesses())
    dispatch(updateShownLocations())
    dispatch(updateShownChallenges())
  }, [dispatch])

  return (
    <>
      {/* ===== header bar =====  */}
      <div id='header-bar'>
        <div
          id='map-search-box-wrapper'
          className={(firstPageOpen && 'first-page-open') || ''}>
          <input
            id='map-search-box'
            className={(firstPageOpen && 'first-page-open') || ''}
            type='text'
            placeholder='Search location...'></input>

          <span
            id='clear-search-input'
            alt='Clear search input'
            onClick={() => clearSearchInput()}
          />
        </div>
      </div>

      <div id='pinapp-logo-wrapper'>
        <img
          src='images/pinapp-go-logo.png'
          alt='PinApp logo'
          id='pinapp-go-logo'
        />
      </div>

      {(currentUser._isAdmin || currentUser._isBusinessOwner) && (
        <div
          id='pages-switch'
          className={(firstPageOpen && 'first-page-open') || ''}
          onClick={() => togglePages()}>
          {firstPageOpen ? (
            <img
              src='images/map/x.svg'
              className='hide-first-page-icon'
              alt='Hide left page'
            />
          ) : (
            <img
              src='images/map/burger-menu.svg'
              className='show-first-page-icon'
              alt='Show left page'
            />
          )}
        </div>
      )}

      {/* ===== left ===== */}
      {menuPageOpen &&
        (currentUser._isAdmin ||
          currentUser._isBusinessOwner ||
          currentUser._isBusinessManager) && (
          <div id='menu-page'>
            <MenuPage
              handleFirstPageContent={handleFirstPageContent}
              clickedMenuOption={clickedMenuOption}
            />
          </div>
        )}

      {firstPageOpen && <div id='first-page'>{renderFirstPageContent()}</div>}

      {secondPageOpen && (
        <div id='second-page'>{renderSecondPageContent()}</div>
      )}

      {thirdPageOpen && <div id='third-page'>{renderThirdPageContent()}</div>}

      {/* ===== right ===== */}
      <div id='user-info-and-menu'>
        <div id='user-info'>
          <img
            className='profile-picture'
            src={
              currentUser.ProfilePictureURL
                ? `${common.imagesBaseUrl}/${currentUser.ProfilePictureURL}`
                : '/images/default-user.png'
            }
            alt='Profile pic'
          />
          <span className='user-name'>{currentUser.FullName}</span>
          <div
            id='user-menu-switch'
            onClick={() => setUerMenuOpen(status => !status)}>
            {userMenuOpen ? (
              <img src='images/map/arrow-up.svg' alt='PinApp logo' />
            ) : (
              <img src='images/map/arrow-down.svg' alt='PinApp logo' />
            )}
          </div>
        </div>

        {userMenuOpen && (
          <div id='user-menu'>
            <div onClick={() => setModalPageContent('ContactUs')}>
              Contact Us
            </div>
            <div onClick={() => setModalPageContent('PrivacyPolicy')}>
              Privacy Policy
            </div>
            <div onClick={() => handleLogOut()}>Log Out</div>
          </div>
        )}
      </div>

      {/* ===== map ===== */}
      <Map
        userMenuOpen={userMenuOpen}
        leftPageOpen={firstPageOpen}
        setUerMenuOpenStatus={setUerMenuOpenStatus}
        openClosePinsPage={showPinsPage}
      />

      {/* ===== modal ===== */}
      <Modal
        open={modalPageContent}
        onClose={() => {
          setModalPageContent(null)
          history.push('/')
        }}
        center>
        {renderModalPage()}
      </Modal>
    </>
  )
}
