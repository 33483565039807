import './Approvals.scss'

import React from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

import {
  approveChallengeStepSubmits,
  approveMultipleChallengeStepSubmits,
} from '../../actions/challengesActions'
import StepToApprove from './StepToApprove'

export default function Approvals(props) {
  const challenge = props.challenge
  const handleThirdPageContent = props.handleThirdPageContent
  const forThirdPage = props.forThirdPage

  const dispatch = useDispatch()

  const {
    currentChallengeUserSubmits,
    stepsToApprove,
    displayedBySeeAllButton,
  } = useSelector(state => state.challenges)

  const steps = forThirdPage ? currentChallengeUserSubmits : stepsToApprove

  const approveAllStepSubmits = () => {
    // that are not already rejected
    if (displayedBySeeAllButton) {
      const challengeSubmitsIds = steps.map(step => step.ID)
      dispatch(approveMultipleChallengeStepSubmits(challengeSubmitsIds))
    } else {
      dispatch(approveChallengeStepSubmits(challenge.ID))
    }
  }

  return (
    <>
      <div className='approvals-top'>
        {forThirdPage ? (
          <span>Challenge Submits By User</span>
        ) : (
          <span>Approvals {displayedBySeeAllButton && ' - All'}</span>
        )}
      </div>

      <div className='approvals-body'>
        {!steps?.length && (
          <div className='no-step-found'>
            <p>There are no submits to approve for this challenge</p>
          </div>
        )}

        {steps?.length > 0 &&
          steps.map((stepToApprove, index) => {
            return (
              <StepToApprove
                key={index}
                isThirdPage={forThirdPage}
                stepToApprove={stepToApprove}
                handleThirdPageContent={handleThirdPageContent}
              />
            )
          })}
      </div>

      <div className='approvals-bottom'>
        <div
          id='approve-all-button'
          onClick={() => approveAllStepSubmits(true)}>
          <span>Approve all</span>
        </div>
      </div>
    </>
  )
}
