import './Challenge.scss'

import React from 'react'

import common from '../../common'
import { formatDate } from '../../utils/helpers'

export default function Challenge(props) {
  let challenge = props.challenge
  let clickEvent = props.customClickEvent
  let currentChallengeId = props.currentChallengeId

  return (
    <div
      id={
        challenge.ID === currentChallengeId
          ? 'row-challenge-list-selected'
          : 'row-challenge-list'
      }
      onClick={() => clickEvent(challenge)}>
      <div id='challenge-logo-wrapper'>
        <img
          id='challenge-logo'
          src={
            !challenge.ImageURL
              ? 'images/default-challenge.png'
              : `${common.imagesBaseUrl}/${challenge.ImageURL}`
          }
          alt='logo'></img>
      </div>
      <div id='challenge-info-wrapper'>
        <div id='challenge-name-text'>{challenge.Title}</div>
        <div id='challenge-description-text'>{challenge.Description}</div>
        <div id='challenge-participants'>
          {challenge.NoOfParticipants} took the challenge
        </div>
        <div id='challenge-date'>
          <span>Date:</span> {formatDate(challenge.StartDate)} -{' '}
          {formatDate(challenge.EndDate)}
        </div>
        <div id='prizes-ribbon'>
          <span>{challenge.NoOfPrizes} prizes</span>
        </div>
      </div>
    </div>
  )
}
