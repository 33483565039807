import '../../App.scss'
import './Welcome.scss'

import React from 'react'
import { Link } from 'react-router-dom'

import ContactUs from '../ContactUs/ContactUs'
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy'
import ForgotPassword from './components/ForgotPassword/ForgotPassword'
import LogIn from './components/LogIn/LogIn'
import ResetPassword from './components/ResetPassword/ResetPassword'
import SignUp from './components/SignUp/SignUp'

export default function Welcome() {
  const path = window.location.pathname

  const renderRightPage = () => {
    switch (path) {
      case '/':
      case '/login':
        return <LogIn />
      case '/signup':
      case '/business-signup':
        return <SignUp />
      case '/forgot-password':
        return <ForgotPassword />
      case '/reset-password':
        return <ResetPassword />
      case '/contact-us':
        return <ContactUs />
      case '/privacy-policy':
      case '/business-privacy-policy':
        return (
          <div className='scrollable-wrapper'>
            <PrivacyPolicy />
          </div>
        )
      default:
        return <></>
    }
  }

  const renderRightPageLink = () => {
    switch (path) {
      case '/':
      case '/login':
      case '/signup':
        return (
          <Link to='/contact-us'>
            <h6 id='contact-us-link'>Contact Us</h6>
          </Link>
        )
      case '/forgot-password':
      case '/reset-password':
      case '/contact-us':
        return (
          <Link to='/login'>
            <h6 id='login-signup-link'>LogIn / SignUp</h6>
          </Link>
        )
      case '/privacy-policy':
        return (
          <Link to='/signup'>
            <h6 id='login-signup-link'>LogIn / SignUp</h6>
          </Link>
        )
      case '/business-privacy-policy':
        return (
          <Link to='/business-signup'>
            <h6 id='login-signup-link'>Business SignUp</h6>
          </Link>
        )
      default:
        return <></>
    }
  }

  return (
    <div className='row'>
      <div id='welcome-left' className='col-7'>
        <h5 id='text-you-can-discover'>
          Discover local boutiques, touristic places, activities, events,
          festivals and more around you.
        </h5>
        <img
          src='/images/pinapp-logo.svg'
          id='pinapp-logo-welcome'
          alt='PinApp logo'
        />
        <h1 id='text-welcome'>Welcome</h1>
        <h6 id='text-pinapp-tech'>PinApp Technology</h6>
        <h4 id='text-motto'>- City as a Playground - </h4>
        <h5 id='text-mission'>
          Win discounts and prizes for taking challenges from local businesses.
        </h5>

        <div id='download-buttons'>
          <a
            href='https://play.google.com/store/apps/details?id=online.pinapp.pinapp'
            target='_blank'
            rel='noopener noreferrer'>
            <img
              id='google-download-banner'
              src='/images/get-google-play.svg'
              alt='Get PinApp on Google Play'
            />
          </a>
          <a
            href='https://itunes.apple.com/ro/app/pinapp/id1400079484'
            target='_blank'
            rel='noopener noreferrer'>
            <img
              id='apple-download-banner'
              src='/images/get-app-store.svg'
              alt='Get PinApp on AppStore'
            />
          </a>
        </div>
      </div>

      <div id='welcome-right' className='col-5'>
        {renderRightPageLink()}

        <div id='welcome-right-page'>{renderRightPage()}</div>
      </div>
    </div>
  )
}
