import axios from 'axios'

import * as actions from '../actionTypes/pinsActionTypes'
import common from '../common'

// const setPinsError = errorMessage => ({
//   type: actions.SET_PINS_ERROR,
//   error: errorMessage || 'Unknown error occured',
// })

export const clearUsersError = () => ({
  type: actions.CLEAR_PINS_ERROR,
})

export const getPinsByLocation =
  (latitude, longitude, take, radius) => async (dispatch, getState) => {
    return null

    // try {
    //   const data = await axios
    //     .get(
    //       `${common.baseUrl}/Messages/GetByLocation?latitude=${latitude}&longitude=${longitude}&take=${take}&radius=${radius}`,
    //       {
    //         headers: {
    //           Authorization: `${localStorage.getItem(
    //             'TokenType'
    //           )} ${localStorage.getItem('AccessToken')}`,
    //         },
    //       }
    //     )
    //     .then(response => response.data)
    //     .then(data => data.Messages)

    //   dispatch(dispatch(mapPinsReceived(data)))
    // } catch (error) {
    //   const errorMessage = safePick(error, 'response', 'data', 'Errors', [0])
    //   dispatch(setPinsError(errorMessage))
    // }
  }

// const mapPinsReceived = mapPins => ({
//   type: actions.MAP_PINS_RECEIVED,
//   mapPins,
// })

export const updateShownPins = pins => ({
  type: actions.UPDATE_SHOWN_PINS,
  pins,
})

const updateStarValue = (pinID, starValue) => ({
  type: actions.UPDATE_GIVEN_STAR_VALUE,
  pinID,
  starValue,
})

export const addStarToPin = pinID => async (dispatch, getState) => {
  try {
    axios
      .put(
        `${common.baseUrl}/Messages/AddStar/${pinID}`,
        {},
        {
          headers: {
            Authorization: `${localStorage.getItem(
              'TokenType'
            )} ${localStorage.getItem('AccessToken')}`,
          },
        }
      )
      .then(() => {
        dispatch(updateStarValue(pinID, true))
      })
  } catch (error) {}
}

export const removeStarFromPin = pinID => async (dispatch, getState) => {
  try {
    await axios
      .put(
        `${common.baseUrl}/Messages/RemoveStar/${pinID}`,
        {},
        {
          headers: {
            Authorization: `${localStorage.getItem(
              'TokenType'
            )} ${localStorage.getItem('AccessToken')}`,
          },
        }
      )
      .then(() => {
        dispatch(updateStarValue(pinID, false))
      })
  } catch (error) {}
}
