import * as actions from '../actionTypes/businessesActionTypes'

let initialState = {
  businesses: [],
  currentBusiness: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_BUSINESSES_ERROR:
      return {
        ...state,
        error: action.error,
      }

    case actions.CLEAR_BUSINESSES_ERROR:
      return {
        ...state,
        error: null,
      }

    case actions.UPDATE_LIST_OF_BUSINESSES:
      const businesses = [action.businessList || []]
      return { ...state, businesses }

    case actions.SET_CURRENT_BUSINESS:
      const currentBusiness = state.businesses[0].find(x => x.Id === action.ID)
      return { ...state, currentBusiness }

    case actions.DELETE_BUSINESS:
      const businessIndex = state.businesses[0].findIndex(
        c => c.Id === action.businessId
      )
      state.businesses[0].splice(businessIndex, 1)
      const businesses2 = [...state.businesses]
      return { ...state, businesses: businesses2 }

    default:
      return state
  }
}
