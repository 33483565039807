import './OpenHours.scss'

import React, { useEffect, useState } from 'react'
import ClickAwayListener from 'react-click-away-listener'
import { useDispatch, useSelector } from 'react-redux'

import { updateCurrentLocationOpenHours } from '../../actions/locationsActions'
import CustomSelect from '../CustomSelect/CustomSelect'
import openHoursData from './openHoursData'

export default function OpenHours(props) {
  const handleThirdPageContent = props.handleThirdPageContent

  const dispatch = useDispatch()

  const locationOpenHours = useSelector(
    state => state.locations.currentLocation?.OpenHours
  )

  const [openHoursOptions] = useState([
    { Id: 1, Name: 'Open' },
    { Id: 2, Name: 'Open 24 hours' },
    { Id: 3, Name: 'Closed' },
  ])

  const [showOpenHoursDropdown, setShowOpenHoursDropdown] = useState()
  const [selectedOpenHourOptionId, setSelectedOpenHourOptionId] = useState()
  const [showTimeOpeningDropdown, setShowTimeOpeningDropdown] = useState([])
  const [showTimeClosingDropdown, setShowTimeClosingDropdown] = useState([])
  const [currentOpenHours, setCurrentOpenHours] = useState(locationOpenHours)

  const openHoursSelectClicked = () => {
    setShowOpenHoursDropdown(!showOpenHoursDropdown)
  }

  const setShowTimeOpeningDropdownHandler = (value, index) => {
    if (value) {
      let newValue = [...showTimeOpeningDropdown]
      newValue[index] = value
      setShowTimeOpeningDropdown(newValue)
    } else {
      setShowTimeOpeningDropdown([])
    }
  }

  const timeOpeningSelectClicked = index => {
    setShowTimeOpeningDropdownHandler(!showTimeOpeningDropdown[index], index)
  }

  const setShowTimeClosingDropdownHandler = (value, index) => {
    if (value) {
      let newValue = [...showTimeClosingDropdown]
      newValue[index] = value
      setShowTimeClosingDropdown(newValue)
    } else {
      setShowTimeClosingDropdown([])
    }
  }

  const timeClosingSelectClicked = index => {
    setShowTimeClosingDropdownHandler(!showTimeClosingDropdown[index], index)
  }

  const setSelectedTimeOpeningHandler = (value, index) => {
    setCurrentOpenHours(
      currentOpenHours.map(x =>
        x.DayOfWeek === index
          ? {
              ...x,
              TimeOpening: openHoursData.openHoursOptions.find(
                x => x.Id === parseInt(value)
              ).Name,
            }
          : x
      )
    )
  }

  const setSelectedTimeClosingHandler = (value, index) => {
    setCurrentOpenHours(
      currentOpenHours.map(x =>
        x.DayOfWeek === index
          ? {
              ...x,
              TimeClosing: openHoursData.openHoursOptions.find(
                x => x.Id === parseInt(value)
              ).Name,
            }
          : x
      )
    )
  }

  const handleSaveOpenHours = () => {
    dispatch(
      updateCurrentLocationOpenHours(currentOpenHours, handleThirdPageContent)
    )
  }

  const getDayOfWeekName = dayOfWeekNumber => {
    const daysOfWeek = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ]

    return daysOfWeek[dayOfWeekNumber]
  }

  const enableDisableOpenHours = (dayOfWeekNumber, checked) => {
    const newHours = checked
      ? { DayOfWeek: dayOfWeekNumber, TimeOpening: null, TimeClosing: null }
      : {
          DayOfWeek: dayOfWeekNumber,
          TimeOpening: '08:00',
          TimeClosing: '18:00',
        }

    setCurrentOpenHours(
      currentOpenHours.map(x =>
        x.DayOfWeek === dayOfWeekNumber ? newHours : x
      )
    )
  }

  useEffect(() => {
    switch (
      selectedOpenHourOptionId //openHoursOptions
    ) {
      case '1': // open
        setCurrentOpenHours(openHoursData.open)
        break
      case '2': // open 24 hours
        setCurrentOpenHours(openHoursData.open24h)
        break
      case '3': // closed
        setCurrentOpenHours(openHoursData.closed)
        break
      default:
        break
    }
  }, [selectedOpenHourOptionId])

  return (
    <>
      <div id='add-open-hours-top'>Open Hours</div>

      <div id='add-open-hours-wrapper'>
        <ClickAwayListener onClickAway={() => setShowOpenHoursDropdown(false)}>
          <div
            id='select-open-hours'
            className='form-control text-input'
            onClick={openHoursSelectClicked}>
            <CustomSelect
              defaultText='Pick open hours'
              optionList={openHoursOptions}
              showOptions={showOpenHoursDropdown}
              setShowOptions={setShowOpenHoursDropdown}
              setSelectedData={setSelectedOpenHourOptionId}
              zIndex={8}
              // preselectedData={}
            />
            <img
              id='select-arrow-task'
              className='select-arrow'
              alt='select arrow'
              src={
                showOpenHoursDropdown
                  ? 'images/close-select-arrow.svg'
                  : 'images/open-select-arrow.svg'
              }></img>
          </div>
        </ClickAwayListener>

        <div className='form-group'>
          {currentOpenHours?.map(x => (
            <div className='open-hours-day'>
              <span>
                <div
                  className={
                    (x.TimeOpening && x.TimeClosing && 'checkbox') ||
                    'checkbox-unchecked'
                  }>
                  <input
                    type='checkbox'
                    checked={(x.TimeOpening && x.TimeClosing && true) || false}
                  />
                  <label
                    htmlFor='checkbox'
                    className='disable-select'
                    onClick={() =>
                      enableDisableOpenHours(
                        x.DayOfWeek,
                        (x.TimeOpening && x.TimeClosing && true) || false
                      )
                    }>
                    <span>{getDayOfWeekName(x.DayOfWeek)}</span>
                  </label>
                </div>
              </span>
              {x.TimeOpening && x.TimeClosing ? (
                <>
                  <span>
                    <ClickAwayListener
                      onClickAway={() =>
                        setShowTimeOpeningDropdownHandler(false, x.DayOfWeek)
                      }>
                      <div
                        id='select-time-opening'
                        className='form-control text-input'
                        onClick={() => timeOpeningSelectClicked(x.DayOfWeek)}>
                        <CustomSelect
                          selectIndex={x.DayOfWeek}
                          defaultText={x.TimeOpening}
                          optionList={openHoursData.openHoursOptions}
                          showOptions={showTimeOpeningDropdown[x.DayOfWeek]}
                          setShowOptions={setShowTimeOpeningDropdownHandler}
                          setSelectedData={setSelectedTimeOpeningHandler}
                          zIndex={parseInt(7 - x.DayOfWeek)}
                          // preselectedData={}
                        />
                        <img
                          id='select-arrow-task'
                          className='select-arrow'
                          alt='select arrow'
                          src={
                            showTimeOpeningDropdown[x.DayOfWeek]
                              ? 'images/close-select-arrow.svg'
                              : 'images/open-select-arrow.svg'
                          }></img>
                      </div>
                    </ClickAwayListener>
                  </span>
                  <span>
                    {' '}
                    <ClickAwayListener
                      onClickAway={() =>
                        setShowTimeClosingDropdownHandler(false, x.DayOfWeek)
                      }>
                      <div
                        id='select-time-closing'
                        className='form-control text-input'
                        onClick={() => timeClosingSelectClicked(x.DayOfWeek)}>
                        <CustomSelect
                          selectIndex={x.DayOfWeek}
                          defaultText={x.TimeClosing}
                          optionList={openHoursData.openHoursOptions}
                          showOptions={showTimeClosingDropdown[x.DayOfWeek]}
                          setShowOptions={setShowTimeClosingDropdownHandler}
                          setSelectedData={setSelectedTimeClosingHandler}
                          zIndex={parseInt(7 - x.DayOfWeek)}
                          // preselectedData={}
                        />
                        <img
                          id='select-arrow-task'
                          className='select-arrow'
                          alt='select arrow'
                          src={
                            showTimeClosingDropdown[x.DayOfWeek]
                              ? 'images/close-select-arrow.svg'
                              : 'images/open-select-arrow.svg'
                          }></img>
                      </div>
                    </ClickAwayListener>
                  </span>
                </>
              ) : (
                <span className='closed-text'>closed</span>
              )}
            </div>
          ))}
        </div>
      </div>

      <div id='add-open-hours-bottom'>
        <div id='cancel-button' onClick={() => handleThirdPageContent('')}>
          Cancel
        </div>
        <div id={'save-btn-enabled'} onClick={handleSaveOpenHours}>
          Save
        </div>
      </div>
    </>
  )
}
