import * as actions from '../actionTypes/pinsActionTypes'

let initialState = {
  mapPins: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_PINS_ERROR:
      return {
        ...state,
        error: action.error,
      }

    case actions.CLEAR_PINS_ERROR:
      return {
        ...state,
        error: null,
      }

    case actions.MAP_PINS_RECEIVED:
      const mapPinsIds = state.mapPins.map(x => x.ID)
      const newMapPins = action.mapPins.filter(
        x => mapPinsIds.indexOf(x.ID) === -1
      )
      return {
        ...state,
        newMapPins,
        mapPins: state.mapPins.concat(newMapPins),
      }

    case actions.UPDATE_SHOWN_PINS:
      return {
        ...state,
        shownPins: action.pins,
      }

    case actions.UPDATE_GIVEN_STAR_VALUE:
      const pinIndex = state.shownPins.findIndex(p => p.ID === action.pinID)
      state.shownPins[pinIndex].StarGiven = action.starValue
      const shownPins = [...state.shownPins]

      return { ...state, shownPins }

    default:
      return state
  }
}
