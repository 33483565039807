export default function safePick(obj, ...props) {
  if (props && props.length > 0) {
    try {
      const value = obj[props[0]]
      props.shift()
      return safePick(value, ...props)
    } catch (error) {
      return null
    }
  }

  return obj === undefined ? null : obj
}
