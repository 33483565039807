import './SignUp.scss'

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { clearUsersError, signUp } from '../../../../actions/usersActions'

export default function SignUp() {
  const dispatch = useDispatch()
  const usersState = useSelector(state => state.users)
  const [signUpData, setSignUpData] = useState({})

  const businessSignUp = window.location.pathname === '/business-signup'

  // error handling
  if (usersState.error) {
    // TODO: show a nice pop-up
    alert(usersState.error)
    dispatch(clearUsersError())
  }

  const signUpDataChanged = e => {
    let { name, value } = e.target
    let newSignUpData = { ...signUpData, [name]: value }
    setSignUpData(newSignUpData)
  }

  const handleSignUp = () => {
    if (businessSignUp) {
      dispatch(signUp({ ...signUpData, business: true }))
    } else {
      dispatch(signUp(signUpData))
    }
  }

  return (
    <>
      <div id='welcome-tabs-wrapper'>
        {!businessSignUp && (
          <Link to='/login'>
            <h3 id='login-tab'>LogIn</h3>
          </Link>
        )}

        {!businessSignUp && (
          <Link to='/signup'>
            <h3 id='signup-tab' className='active-text'>
              SignUp
            </h3>
          </Link>
        )}

        {businessSignUp && (
          <h3 id='signup-tab' className='active-text'>
            Business SignUp
          </h3>
        )}
      </div>

      <input
        type='text'
        name='fullname'
        placeholder='Name'
        aria-label='Name'
        className='form-control text-input'
        onChange={signUpDataChanged}
      />
      <input
        type='text'
        name='email'
        placeholder='Email'
        aria-label='Email'
        className='form-control text-input'
        onChange={signUpDataChanged}
      />
      <input
        type='password'
        name='password'
        placeholder='Password'
        aria-label='Password'
        className='form-control text-input password-input'
        onChange={signUpDataChanged}
      />
      <input
        type='password'
        name='confirmPassword'
        placeholder='Repeat Password'
        aria-label='Repeat Password'
        className='form-control text-input password-input'
        onChange={signUpDataChanged}
      />
      {!businessSignUp && (
        <Link to='/privacy-policy'>
          <h6 id='privacy-policy'>Privacy Policy</h6>
        </Link>
      )}
      {businessSignUp && (
        <Link to='/business-privacy-policy'>
          <h6 id='privacy-policy'>Privacy Policy</h6>
        </Link>
      )}
      <button
        type='button'
        className='btn'
        id='btn-signup'
        onClick={handleSignUp}>
        <b>Sign Up</b>
      </button>
    </>
  )
}
