import './AddLocation.scss'

import React, { useEffect, useRef, useState } from 'react'
import ClickAwayListener from 'react-click-away-listener'
import { useDispatch, useSelector } from 'react-redux'

import {
  clearLocationsError,
  getLocationTypesAction,
  saveLocation,
} from '../../actions/locationsActions'
import common from '../../common'
import CustomSelect from '../CustomSelect/CustomSelect'

export default function AddLocation(props) {
  const selectedLocation = props.location
  const handleSecondPageContent = props.handleSecondPageContent
  const handleThirdPageContent = props.handleThirdPageContent

  const locationsState = useSelector(state => state.locations)
  const businesses = useSelector(state => state.businesses.businesses)
  const locationTypes = useSelector(state => state.locations.locationTypes)
  const currentUser = useSelector(state => state.users.currentUser)
  const openHours = useSelector(
    state => state.locations.currentLocation?.OpenHours
  )

  const [location, setLocation] = useState()
  const [name, setName] = useState()
  const [addressName, setAddressName] = useState()
  const [description, setDescription] = useState()
  const [longitude, setLongitude] = useState()
  const [latitude, setLatitude] = useState()
  const [businessId, setBusinessId] = useState()
  const [locationTypeId, setLocationTypeId] = useState()
  const [locationImages, setLocationImages] = useState([])
  const [selectedFormDataArray, setSelectedFormDataArray] = useState([])
  const [showBusinessSelect, setShowBusinessSelect] = useState(false)
  const [showTypesSelect, setShowTypesSelect] = useState(false)
  const [predefImages, setPredefImages] = useState([])
  const inputImageRef = useRef()

  const placeInputRef = useRef()

  let numerOfPredefImages = (selectedLocation && predefImages?.length) || 0
  const dispatch = useDispatch()

  const handleImageRemoval = imag => {
    let images = [...(locationImages || [])]
    let formDatas = [...(selectedFormDataArray || [])]
    const index = images.indexOf(imag)
    if (index > -1) {
      images.splice(index, 1)

      if (index >= numerOfPredefImages) {
        // It was deleted an image added in this session so we also delete the form data
        formDatas.splice(index - numerOfPredefImages, 1)
      } else {
        // It was deleted an existing image
        --numerOfPredefImages
        let imagesP = [...(predefImages || [])]
        imagesP.splice(index, 1)
        if (location) {
          let cntPos = 0
          while (cntPos < numerOfPredefImages) {
            imagesP[cntPos].Position = cntPos
            ++cntPos
          }
          setPredefImages(imagesP)
        }
      }
    }
    setLocationImages(images)
    setSelectedFormDataArray(formDatas)
  }

  const fileSelectedHandler = event => {
    if (event.target.files.length !== 0) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onload = () => {
        if (reader.readyState === 2) {
          setLocationImages([...locationImages, reader.result])
        }
      }
      reader.readAsDataURL(file)
      setSelectedFormDataArray([...selectedFormDataArray, file])
    }
  }

  if (locationsState.error) {
    alert(locationsState.error)
    dispatch(clearLocationsError())
  }

  const initPlaceAPI = () => {
    const autocomplete = new window.google.maps.places.Autocomplete(
      placeInputRef.current
    )

    new window.google.maps.event.addListener(
      autocomplete,
      'place_changed',
      function () {
        let place = autocomplete.getPlace()
        setAddressName(place.formatted_address)
        setLatitude(place.geometry.location.lat())
        setLongitude(place.geometry.location.lng())
      }
    )
  }

  const addressSelected = e => {
    if (e.target.value) {
      setAddressName(e.target.value)
    } else {
      setLatitude(null)
      setLongitude(null)
    }
  }

  const businessesSelectClicked = () => {
    setShowBusinessSelect(!showBusinessSelect)
    setShowTypesSelect(false)
  }

  const typesSelectClicked = () => {
    setShowTypesSelect(!showTypesSelect)
    setShowBusinessSelect(false)
  }

  const handleSaveLocation = () => {
    if (
      name &&
      addressName &&
      latitude &&
      longitude &&
      businessId &&
      locationTypeId &&
      description &&
      locationImages[0]
    ) {
      let editedLocation = location
        ? {
            // edit
            ID: location.ID,
            Admins: location.Admins.map(a => a.ID),
            BusinessTypeID: locationTypeId,
            BusinessId: businessId,
            Confirmed: location.Confirmed,
            Description: description,
            Latitude: latitude,
            Longitude: longitude,
            LocationName: addressName,
            Name: name,
            Images: predefImages,
            OpenHours: openHours,
          }
        : {
            // new
            Admins: [currentUser.ID],
            BusinessTypeID: locationTypeId,
            BusinessId: businessId,
            Confirmed: false,
            Description: description,
            Latitude: latitude,
            Longitude: longitude,
            LocationName: addressName,
            Name: name,
            OpenHours: openHours,
          }

      dispatch(
        saveLocation(
          editedLocation,
          selectedFormDataArray,
          handleSecondPageContent,
          currentUser
        )
      )
    }
  }

  const handleAddImage = () => {
    inputImageRef.current.click()
  }

  useEffect(() => {
    dispatch(getLocationTypesAction())
  }, [dispatch])

  useEffect(() => {
    setLocation(selectedLocation)
    initPlaceAPI()
    if (location) {
      setName(location.Name)
      setAddressName(location.LocationName)
      setLatitude(location.Latitude)
      setLongitude(location.Longitude)
      setDescription(location.Description)
      let images = location.Images
      if (images) {
        images.sort((a, b) => a.Position - b.Position)
      }
      setPredefImages(images)

      let editImages = images?.map(img => `${common.imagesBaseUrl}/${img.URL}`)

      setLocationImages(editImages)
    }
  }, [location, selectedLocation])

  return (
    <>
      <div id='add-location-top'>Location Information</div>

      <div id='add-location-body'>
        <input
          type='text'
          name='name'
          placeholder='Location Name'
          aria-label='Location Name'
          className='form-control text-input add-input'
          onChange={e => setName(e.target.value || '')}
          value={name}
          autoComplete='off'
        />

        <input
          ref={placeInputRef}
          type='text'
          name='address'
          placeholder='Address'
          aria-label='Address'
          className='form-control text-input add-input'
          onChange={addressSelected}
          value={addressName}
        />

        <ClickAwayListener onClickAway={() => setShowBusinessSelect(false)}>
          <div
            id='select-business'
            className='form-control text-input'
            onClick={businessesSelectClicked}>
            <CustomSelect
              defaultText='Business'
              optionList={businesses[0]}
              showOptions={showBusinessSelect}
              setShowOptions={setShowBusinessSelect}
              setSelectedData={setBusinessId}
              preselectedData={location ? location.Business : null}
            />
            <img
              className='select-arrow'
              alt='select arrow'
              src={
                showBusinessSelect
                  ? 'images/close-select-arrow.svg'
                  : 'images/open-select-arrow.svg'
              }
            />
          </div>
        </ClickAwayListener>

        <ClickAwayListener onClickAway={() => setShowTypesSelect(false)}>
          <div
            id='select-type'
            className='form-control text-input'
            onClick={typesSelectClicked}>
            <CustomSelect
              defaultText='Location Type'
              optionList={locationTypes}
              showOptions={showTypesSelect}
              setShowOptions={setShowTypesSelect}
              aboveSelectOpen={showBusinessSelect}
              setSelectedData={setLocationTypeId}
              preselectedData={location ? location.BusinessType : null}
            />
            <img
              id='select-arrow-types'
              className='select-arrow'
              alt='select arrow'
              src={
                showTypesSelect
                  ? 'images/close-select-arrow.svg'
                  : 'images/open-select-arrow.svg'
              }></img>
          </div>
        </ClickAwayListener>

        <div className='form-group'>
          <textarea
            id='location-description'
            className='form-control text-multiline-input'
            name='description'
            placeholder='Description'
            maxLength='100'
            value={description}
            onChange={e => setDescription(e.target.value || '')}></textarea>
          {!description && (
            <div id='characters-restriction'>max 100 characters</div>
          )}
        </div>

        <p className='add-page-text'>Open hours</p>

        <div
          id='add-open-hours-button'
          onClick={() => handleThirdPageContent('OpenHours')}>
          + Add
        </div>

        <p className='add-page-text'>Photos</p>

        <div id='location-images-wrapper'>
          {locationImages?.length > 0 &&
            locationImages.map((img, index) => {
              return (
                <div id='location-image-wrapper' key={index}>
                  <img
                    className='location-image'
                    src={img}
                    alt='uploaded'></img>

                  <div
                    id='delete-image-button'
                    onClick={() => handleImageRemoval(img)}>
                    <img
                      id='delete-image'
                      src='images/trash_black.svg'
                      alt='delete'></img>
                  </div>
                </div>
              )
            })}

          {locationImages?.length < 3 && (
            <div className='browse-photo-wrapper'>
              <div>
                <div
                  className='browse-photo-round-wrapper'
                  onClick={handleAddImage}>
                  <img
                    id='plus-button'
                    src='images/plus_logo_business_dark.svg'
                    alt='+'></img>
                </div>
                <div className='browse-photo-icon'>
                  <label className='browse-photo-input'>
                    <input
                      ref={inputImageRef}
                      type='file'
                      onChange={fileSelectedHandler}
                      accept='image/*'
                    />
                  </label>
                  <img
                    src='images/photo_camera-24px.svg'
                    alt='browse icon'></img>
                  <p>Photo</p>
                </div>
              </div>
            </div>
          )}
        </div>

        {!locationImages[0] && (
          <p id='min-photo-number'>Add at least one photo</p>
        )}
      </div>

      <div id='add-location-bottom'>
        <div
          id='cancel-button'
          onClick={() =>
            handleSecondPageContent(location ? 'LocationDetails' : '')
          }>
          Cancel
        </div>
        <div
          id={
            name &&
            addressName &&
            latitude &&
            longitude &&
            businessId &&
            locationTypeId &&
            description &&
            locationImages[0]
              ? 'save-btn-enabled'
              : 'save-btn-disabled'
          }
          onClick={handleSaveLocation}>
          Save
        </div>
      </div>
    </>
  )
}
