import './OngoingChallenges.scss'

import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

import {
  clearChallengesError,
  getAllChallengeStepSubmitsToApprove,
  getChallengeStepsToApprove,
  setCurrentChallenge,
} from '../../actions/challengesActions'
import Challenge from '../Challenge/Challenge'

export default function OngoingChallenges(props) {
  const handleSecondPageContent = props.handleSecondPageContent
  const challenges = props.challenges

  const dispatch = useDispatch()
  const challengesState = useSelector(state => state.challenges)
  const currentUser = useSelector(state => state.users.currentUser)

  if (challengesState.error) {
    alert(challengesState.error)
    dispatch(clearChallengesError())
  }

  const [selectedChallengeId, setSelectedChallengeId] = useState()

  const fetchAllChallengeStepsToApprove = () => {
    setSelectedChallengeId(null)
    dispatch(getAllChallengeStepSubmitsToApprove())
    handleSecondPageContent('Approvals', !!selectedChallengeId)
  }

  const challengeClicked = challenge => {
    setSelectedChallengeId(challenge.ID)
    dispatch(setCurrentChallenge(challenge.ID))
    dispatch(getChallengeStepsToApprove(challenge.ID))
    handleSecondPageContent('Approvals', selectedChallengeId !== challenge.ID)
  }

  return (
    <>
      <div className='ongoing-challenges-top'>
        <span>Ongoing Challenges</span>
        {currentUser._isAdmin && (
          <button
            onClick={fetchAllChallengeStepsToApprove}
            className='see-all-submits-button'>
            See All
          </button>
        )}
      </div>

      <div className='ongoing-challenges-body'>
        {!challenges?.length && (
          <>
            <div className='no-challenge-found'>
              <img src='images/challenges_logo.svg' alt='challenges'></img>

              <p>Add challenges for your customers to make shopping more fun</p>
            </div>
          </>
        )}

        {challenges?.length > 0 &&
          props.challenges.map((challenge, index) => {
            return (
              <Challenge
                key={index}
                challenge={challenge}
                customClickEvent={challengeClicked}
                currentChallengeId={selectedChallengeId}
              />
            )
          })}
      </div>
    </>
  )
}
