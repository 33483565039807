import axios from 'axios'

import * as actions from '../actionTypes/businessesActionTypes'
import common from '../common'
import safePick from '../utils/safePick'
import { updateShownChallenges } from './challengesActions'
import { updateShownLocations } from './locationsActions'

const refreshBusinessList = businessList => ({
  type: actions.UPDATE_LIST_OF_BUSINESSES,
  businessList,
})

const deleteBusinessAction = businessId => ({
  type: actions.DELETE_BUSINESS,
  businessId,
})

export const setCurrentBusiness = ID => ({
  type: actions.SET_CURRENT_BUSINESS,
  ID,
})

const setBusinessesError = errorMessage => ({
  type: actions.SET_BUSINESSES_ERROR,
  error: errorMessage || 'Unknown error occured',
})

export const saveBusiness =
  (business, businessLogo, handleSecondPageContent, currentUser) =>
  async (dispatch, getState) => {
    try {
      if (businessLogo) {
        // upload image
        const fd = new FormData()
        fd.append('File', businessLogo, businessLogo.name)

        const logoUrl = await axios
          .post(`${common.baseUrl}/Businesses/UploadPicture`, fd, {
            headers: {
              Authorization: `${localStorage.getItem(
                'TokenType'
              )} ${localStorage.getItem('AccessToken')}`,
            },
          })
          .then(response => response.data)

        business.logoUrl = logoUrl
      }

      const url = business.Id
        ? `${common.baseUrl}/Businesses/Update`
        : `${common.baseUrl}/Businesses/Add`

      const req = business.Id ? axios.put : axios.post

      await req(url, business, {
        headers: {
          Authorization: `${localStorage.getItem(
            'TokenType'
          )} ${localStorage.getItem('AccessToken')}`,
        },
      }).then(async () => {
        await axios
          .get(`${common.baseUrl}/Businesses/GetByUserId/${currentUser.ID}`, {
            headers: {
              Authorization: `${localStorage.getItem(
                'TokenType'
              )} ${localStorage.getItem('AccessToken')}`,
            },
          })
          .then(async response => {
            dispatch(refreshBusinessList(response.data.Businesses))
            dispatch(
              setCurrentBusiness(
                business.Id || response.data.Businesses.find(x => true)?.Id // edited or added
              )
            ) // first
            handleSecondPageContent('BusinessDetails')
          })
      })
    } catch (error) {
      const errorMessage = safePick(error, 'response', 'data', 'Errors', [0])
      dispatch(setBusinessesError(errorMessage))
    }
  }

export const updateShownBusinesses = () => async (dispatch, getState) => {
  const currentUserId = getState().users.currentUser.ID

  await axios
    .get(`${common.baseUrl}/Businesses/GetByUserId/${currentUserId}`, {
      headers: {
        Authorization: `${localStorage.getItem(
          'TokenType'
        )} ${localStorage.getItem('AccessToken')}`,
      },
    })
    .then(async response => {
      dispatch(refreshBusinessList(response.data.Businesses))
    })
}

export const deleteBusiness =
  (businessId, handleSecondPageContent) => async (dispatch, getState) => {
    await axios
      .delete(`${common.baseUrl}/Businesses/Delete/${businessId}`, {
        headers: {
          Authorization: `${localStorage.getItem(
            'TokenType'
          )} ${localStorage.getItem('AccessToken')}`,
        },
      })
      .then(() => {
        dispatch(deleteBusinessAction(businessId))
        dispatch(updateShownLocations())
        dispatch(updateShownChallenges())
        handleSecondPageContent('BusinessDetails')
      })
  }

export const clearBusinessesError = () => ({
  type: actions.CLEAR_BUSINESSES_ERROR,
})
