import './LogIn.scss'

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import {
  authenicate,
  clearUsersError,
  getCurrentUserRoles,
} from '../../../../actions/usersActions'

export default function LogIn() {
  const dispatch = useDispatch()
  const usersState = useSelector(state => state.users)
  const [authData, setAuthData] = useState({})
  const [stayLoggedIn, setStayLoggedIn] = useState(true)

  // error handling
  if (usersState.error) {
    // TODO: show a nice pop-up
    alert(usersState.error)
    dispatch(clearUsersError())
  }

  const authDataChanged = e => {
    let { name, value } = e.target
    setAuthData({ ...authData, [name]: value })
  }

  const handleLoginKeyDown = e => {
    if (e.key === 'Enter') {
      login()
    }
  }

  const login = async () => {
    await dispatch(authenicate(authData.email, authData.password, stayLoggedIn))
    dispatch(getCurrentUserRoles())
  }

  // const facebookLogin = () => {
  //   return
  // }

  return (
    <>
      <div id='welcome-tabs-wrapper'>
        <Link to='/login'>
          <h3 id='login-tab' className='active-text'>
            LogIn
          </h3>
        </Link>

        <Link to='/signup'>
          <h3 id='signup-tab'>SignUp</h3>
        </Link>
      </div>

      <input
        type='text'
        name='email'
        placeholder='Email'
        aria-label='Email'
        className='form-control text-input email-input'
        onChange={authDataChanged}
        onKeyDown={e => handleLoginKeyDown(e)}
      />
      <input
        type='password'
        name='password'
        placeholder='Password'
        aria-label='Password'
        className='form-control text-input password-input'
        onChange={authDataChanged}
        onKeyDown={e => handleLoginKeyDown(e)}
      />

      <Link to='/forgot-password'>
        <h6 id='forgot-password'>Forgot Password</h6>
      </Link>

      <div id='stay-logged-in'>
        <div className='checkbox'>
          <input
            type='checkbox'
            id='checkbox'
            name='stay-logged-in'
            checked={stayLoggedIn}
            onChange={() => setStayLoggedIn(!stayLoggedIn)}
          />
          <label
            htmlFor='checkbox'
            id='stay-logged-in-label'
            className='disable-select'>
            <span>Stay Logged In</span>
          </label>
        </div>
      </div>

      <div id='buttons-wrapper'>
        <button
          type='button'
          className='btn'
          id='btn-active'
          onClick={() => {
            login()
          }}>
          <b>LogIn</b>
        </button>
        {/* <button
          type='button'
          className='btn'
          id='btn-fb-login'
          onClick={() => {
            facebookLogin()
          }}>
          facebook
        </button> */}
      </div>
    </>
  )
}
