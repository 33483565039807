import './ForgotPassword.scss'

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  clearUsersError,
  forgotPassword,
  setForgotPwEmailSent,
} from '../../../../actions/usersActions'

export default function ForgotPassword() {
  const dispatch = useDispatch()
  const usersState = useSelector(state => state.users)
  const [email, setEmail] = useState()

  // error handling
  if (usersState.error) {
    // TODO: show a nice pop-up
    alert(usersState.error)
    dispatch(clearUsersError())
  }

  // success handling
  if (usersState.forgotPasswordEmailSent) {
    // TODO: show a nice pop-up
    alert('Email sent!')
    // TODO: clear email input
    dispatch(setForgotPwEmailSent(null))
  }

  const emailChanged = e => {
    let { value } = e.target
    setEmail(value)
  }

  const sendForgotPasswordEmail = () => {
    if (email) {
      dispatch(forgotPassword(email))
    } else {
      // TODO: show a nice pop-up
      alert('Please fill in the email!')
    }
  }

  return (
    <>
      <h3 id='forgot-your-password'>Forgot your password?</h3>
      <h6 id='forgot-your-password-instructions'>
        Write down your email and we'll send you a reset password link
      </h6>
      <input
        type='text'
        name='email'
        placeholder='Email'
        aria-label='Email'
        className='form-control text-input'
        onChange={emailChanged}
      />
      <button
        type='button'
        className='btn'
        id='btn-send-email'
        onClick={sendForgotPasswordEmail}>
        <b>Send Email</b>
      </button>
    </>
  )
}
