import * as actions from '../actionTypes/locationsActionTypes'

let initialState = {
  locations: [],
  locationTypes: [],
  currentLocation: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_LOCATIONS_ERROR:
      return {
        ...state,
        error: action.error,
      }

    case actions.CLEAR_LOCATIONS_ERROR:
      return {
        ...state,
        error: null,
      }

    case actions.UPDATE_LIST_OF_LOCATIONS:
      const locations = [action.locationList || []]
      return { ...state, locations }

    case actions.GET_LOCATION_TYPES:
      const locationTypes = action.locationTypes
      return { ...state, locationTypes }

    case actions.SET_CURRENT_LOCATION:
      const currentLocation = state.locations[0].find(x => x.ID === action.ID)
      return { ...state, currentLocation }

    case actions.SET_CURRENT_LOCATION_OPEN_HOURS:
      const currentLocationData = state.currentLocation
      return {
        ...state,
        currentLocation: {
          ...currentLocationData,
          OpenHours: action.openHours,
        },
      }

    case actions.DELETE_LOCATION:
      const locationIndex = state.locations[0].findIndex(
        l => l.ID === action.locationID
      )
      state.locations[0].splice(locationIndex, 1)
      const locations2 = [...state.locations]
      return { ...state, locations: locations2 }

    default:
      return state
  }
}
