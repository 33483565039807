import './Locations.scss'

import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { setCurrentLocation } from '../../actions/locationsActions'
import Location from '../Location/Location'

export default function Locations(props) {
  const handleSecondPageContent = props.handleSecondPageContent

  const dispatch = useDispatch()
  const [selectedLocationId, setSelectedLocationId] = useState()

  const locationClicked = location => {
    setSelectedLocationId(location.ID)
    dispatch(setCurrentLocation(location.ID))
    handleSecondPageContent(
      'LocationDetails',
      selectedLocationId !== location.ID
    )
  }

  const addClicked = () => {
    setSelectedLocationId('')
    dispatch(setCurrentLocation(''))
    handleSecondPageContent('AddLocation')
  }

  return (
    <>
      <div className='locations-top'>
        <span>Locations</span>
      </div>

      <div className='locations-list'>
        {props.locations &&
          props.locations[0] &&
          props.locations.map((location, index) => {
            return (
              <Location
                key={index}
                location={location}
                customClickEvent={locationClicked}
                currentLocationId={selectedLocationId}
              />
            )
          })}
        {!props?.locations?.length && (
          <>
            <div className='no-location-found'>
              <p>Add your locations</p>
            </div>
          </>
        )}
      </div>

      <div className='locations-bottom'>
        <div className='add-btn' onClick={() => addClicked()}>
          + Add
        </div>
      </div>
    </>
  )
}
