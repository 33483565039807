import './PrivacyPolicy.scss'

import React, { useState } from 'react'

export default function PrivacyPolicy() {
  const [language /*, setLanguage*/] = useState('EN')

  // const businessTerms =
  //   window.location.pathname === '/business-privacy-policy'

  return (
    <>
      {/* <span className='change-language' onClick={() => setLanguage('EN')}>
        EN
      </span>
      <span className='change-language' onClick={() => setLanguage('RO')}>
        RO
      </span>

      <br /> */}
      <br />

      {language === 'EN' && (
        <>
          <h3 className='title'>Privacy Policy</h3>

          <br />

          <code>
            <h5>
              <b>Welcome to Pinapp GO!</b>
            </h5>
            Pinapp GO creates meaningful experiences between people and brands
            in unique, smart, and funny ways through rewarding challenges.
            <br />
            <br />
            By using our Service, you understand and agree that we provide you
            with a platform to post content, such as photos, comments and other
            materials (the "User Content") within the Service, and to publicly
            distribute the User Content. This means that other Users may search,
            view, use, or distribute any content of Your Content that you make
            publicly accessible through the Service in accordance with the terms
            and conditions of this Privacy Policy and Terms of Use Our policy
            applies to all visitors, users and other people accessing the
            Service ("Users").
          </code>

          <br />
          <br />

          <code>
            <h5>
              <b>1. The information we collect:</b>
            </h5>
            Your username, password, and email address when you create a Pinapp
            GO account. Profile information you enter to create your user
            profile (for example, first and last name, photo, phone number).
            This information allows you to "find" you and other users on Pinapp
            GO. The user's content (for example, photos, comments, and other
            materials) that you post within the Service. Communications between
            you and Pinapp GO. For example, we may send you emails about the
            Service (such as account confirmation emails, changes / updates to
            some features of the Service, technical and security notifications).
            Please note that you cannot waive receiving Service emails. We may
            ask promoters or other partners to submit ads or services to your
            devices that may use cookies or similar technologies placed by us or
            third parties.
            <br />
          </code>

          <br />

          <code>
            <h5>
              <b>2. Communicating Your Information:</b>
            </h5>
            We will not rent or sell your information to third parties outside
            the Pinapp GO (or outside of the Pinapp GO group of companies)
            without your approval, except as outlined in this Policy.
            <br />
          </code>

          <br />

          <code>
            <h5>
              <b>Parties to whom you may distribute Your Content:</b>
            </h5>
            Any information or content items that you voluntarily communicate to
            be posted on the Service, such as User Content, become available to
            the general public, within the limits you set with the applicable
            privacy settings. To change your privacy settings within the
            Service, please change the profile setting. Once you have shared or
            made public a User Content item, that item may be redistributed by
            others. Within the limits imposed by your profile settings and your
            privacy settings, any User Content you make public may be searched
            for by other Users.
            <br />
          </code>

          <br />

          <code>
            <h5>
              <b>What happens if there are changes to your company control:</b>
            </h5>
            If we sell or otherwise transfer Pinapp GO or our assets, in whole
            or in part, to another organization (for example, in a transaction
            such as a merger, acquisition, bankruptcy, dissolution,
            liquidation), your information as well your name and email address,
            Your Content and any other information collected through the Service
            may be among the items sold or assigned. You will still be the owner
            of your Content. The Buyer or transferee will have to comply with
            our commitments under this Privacy Policy.
            <br />
          </code>

          <br />

          <code>
            <h5>
              <b>Responses to Legal Claims and Prevention of Damage:</b>
            </h5>
            We may access, retain and transmit your information in response to a
            legal request (such as a search warrant, a court order or a quote)
            if, in good faith, we believe that the law requires us . These
            include receiving legal claims from jurisdictions outside Romanian
            when, in good faith, we consider that this action is imposed by law
            in that jurisdiction, affects users in that jurisdiction and is in
            compliance with internationally recognized standards. It is also
            possible to access, store and transmit information when, in good
            faith, we believe that is necessary: ​​to detect, prevent and
            resolve cases of fraud and other illegal activities; to protect us,
            yourself and others, including in investigations; or to prevent the
            death or personal injury of some people. The information we receive
            about you may be accessed, processed and kept for a longer period of
            time when it is subject to a legal request, government inquiry or
            investigation into possible violations of our terms and policies, or
            otherwise prevent potential damage.
            <br />
          </code>

          <br />

          <code>
            <h5>
              <b>3. Storing Your Information:</b>
            </h5>
            You are permanently responsible for ensuring the secret of your
            unique password and account information as well as for controlling
            access to your emails with Pinapp GO.
            <br />
          </code>

          <br />

          <code>
            <h5>
              <b>4.Options you have about your information.</b>
            </h5>
            <br />
            <h5>
              <b>
                Your account information and privacy settings for your profile:
              </b>
            </h5>
            You can update or delete your account at any time by logging in and
            going on your profile settings.
            <br />
          </code>

          <br />

          <code>
            <h5>
              <b>How Long Do We Keep Your Content:</b>
            </h5>
            When you delete your account in the ‘Profile Settings’, all account
            information is deleted and you won't be able to recover this later.
            Information that others have shared about you isn't part of your
            account and won't be deleted.
            <br />
          </code>

          <br />

          <code>
            <h5>
              <b>How to delete your personal data in app:</b>
            </h5>
            You can delete your account directly from the app.
            <ol>
              <li>Go to your profile.</li>
              <li>Select the settings button on the left corner.</li>
              <li>Select Edit Account from the menu.</li>
              <li>Select Delete.</li>
              <li>Enter your password and press the delete button.</li>
              <li>Your Personal Data will be deleted.</li>
            </ol>
            <br />
          </code>

          <code>
            <h5>
              <b>How to request your personal data deletion over email:</b>
            </h5>
            <ol>
              <li>Send your request to our email: pinappgo@gmail.com.</li>
              <li>Request your personal data to be deleted.</li>
              <li>Your Personal Data will be deleted upon request.</li>
            </ol>
            <br />
          </code>

          <code>
            <h5>
              <b>5. Changes to privacy policies:</b>
            </h5>
            Pinapp GO can modify or update this Privacy Policy from time to
            time; so please consult it periodically. We may also communicate
            changes and updates to you in other ways, depending on the
            circumstances. The continued use of Pinapp GO or the Service after
            making any changes to this Privacy Policy is your agreement to these
            changes.
            <br />
          </code>
        </>
      )}

      {/* {language === 'RO' && (
        <>
          <h3 className='title'>Termeni și condiții</h3>

          <br />

          <code>
            Bun venit la PinApp! PinApp oferă un mod rapid, minunat și
            distractiv de a distribui elemente media prin intermediul platformei
            noastre pentru distribuire de conținut mesaje, poze, videouri ce
            sunt conectate la o locație.
            <br />
            <br />
            Prin utilizarea Serviciului nostru, înțelegeți și sunteți de acord
            că vă oferim o platformă pentru a posta conținut, precum fotografii,
            comentarii și alte materiale („Conținutul utilizatorului”), în
            cadrul Serviciului și pentru a distribui în mod public Conținutul
            utilizatorului. Acest lucru înseamnă că alți Utilizatori pot căuta,
            vedea, folosi sau distribui orice element al Conținutului dvs. pe
            care îl faceți accesibil în mod public prin intermediul Serviciului,
            în conformitate cu termenii și condițiile prezentei Politici de
            confidențialitate și ale Condițiilor noastre de utilizare.
            <br />
            Politica noastră se aplică tuturor vizitatorilor, utilizatorilor și
            altor persoane care accesează Serviciul („Utilizatori”).
            <br />
          </code>

          <br />

          <code>
            <h5>
              <b>1. Informațiile pe care le colectăm:</b>
            </h5>
            Numele de utilizator, parola și adresa de e-mail, atunci când vă
            creați un cont PinApp.
            <br />
            Informații de profil pe care le introduceți pentru a vă crea
            profilul de utilizator (de exemplu, prenumele și numele de familie,
            fotografia, numărul de telefon). Aceste informații permit „găsirea”
            dvs. și a altor utilizatori pe PinApp.
            <br />
            Conținutul utilizatorului (de exemplu, fotografii, comentarii și
            alte materiale) pe care îl postați în cadrul Serviciului.
            <br />
            Comunicări între dvs. și PinApp. De exemplu, este posibil să vă
            trimitem e-mailuri privind Serviciul (precum e-mailuri pentru
            confirmarea contului, privind modificări/actualizări ale unor
            funcții din cadrul Serviciului, notificări tehnice și de
            securitate). Rețineți că nu puteți renunța la primirea e-mailurilor
            privind Serviciul.
            <br />
            Este posibil să solicităm promotorilor sau altor parteneri să
            prezinte pe dispozitivele dvs. reclame sau servicii, care pot folosi
            module cookie sau tehnologii similare plasate de noi sau de terți.
            <br />
          </code>

          <br />

          <code>
            <h5>
              <b>2. Comunicarea informațiilor dvs.:</b>
            </h5>
            Nu vom închiria sau vinde informațiile dvs. unor terți din afara
            PinApp (sau din afara grupului de companii din care face parte
            PinApp) fără aprobarea dvs., cu excepția situațiilor menționate în
            prezenta Politică.
            <br />
          </code>

          <br />

          <code>
            <h5>
              <b>Părțile cărora le puteți distribui Conținutul dvs.:</b>
            </h5>
            Orice informații sau elemente de conținut pe care le comunicați în
            mod voluntar pentru a fi postate în cadrul Serviciului, precum
            Conținutul utilizatorului, devin disponibile publicului larg, în
            limitele pe care le stabiliți cu ajutorul setărilor de
            confidențialitate aplicabile. Pentru a vă schimba setările de
            confidențialitate din cadrul Serviciului, vă rugăm să schimbați
            setarea profilului. Odată ce ați distribuit sau ați făcut public un
            element de Conținut al utilizatorului, acel element poate fi
            redistribuit de alte persoane.
            <br />
            În limitele impuse prin setările profilului dvs. și prin setările
            dvs. de confidențialitate, orice element de Conținut al
            utilizatorului pe care îl faceți public poate fi căutat de alți
            Utilizatori.
            <br />
          </code>

          <br />

          <code>
            <h5>
              <b>
                Ce se întâmplă dacă apar modificări privind controlul asupra
                companiei:
              </b>
            </h5>
            Dacă vindem sau cesionăm în orice alt mod PinApp sau activele
            noastre, în parte sau în întregime, către o altă organizație (de
            exemplu, în cadrul unei tranzacții precum o fuziune, o achiziție,
            faliment, dizolvare, lichidare), informațiile dvs. precum numele și
            adresa de e-mail, Conținutul dvs. și orice alte informații colectate
            prin intermediul Serviciului se pot număra printre elementele
            vândute sau cesionate. Veți fi în continuare proprietarul
            Conținutului dvs. Cumpărătorul sau cesionarul va trebui să respecte
            angajamentele asumate de noi în cadrul prezentei Politici de
            confidențialitate.
            <br />
          </code>

          <br />

          <code>
            <h5>
              <b>
                Răspunsurile la solicitările de natură juridică și prevenirea
                prejudiciilor:
              </b>
            </h5>
            Este posibil să accesăm, să păstrăm și să transmitem informațiile
            tale ca răspuns la o solicitare de natură juridică (precum un mandat
            de percheziție, o hotărâre judecătorească sau o citație), dacă,
            fiind de bună-credință, considerăm că legea ne impune acest lucru.
            Printre aceste situații se numără primirea unor solicitări de natură
            juridică din jurisdicții aflate în afara Statelor Unite, atunci
            când, fiind de bună-credință, considerăm că această acțiune este
            impusă de lege în jurisdicția respectivă, că afectează utilizatorii
            din jurisdicția respectivă și că este conformă cu standardele
            recunoscute la nivel internațional. De asemenea, este posibil să
            accesăm, să păstrăm și să transmitem informații atunci când, fiind
            de bună-credință, considerăm că acest lucru este necesar: pentru a
            detecta, a preveni și a rezolva cazuri de fraudă și alte activități
            ilegale; pentru ne proteja pe noi, pe dvs. și alte persoane,
            inclusiv în cadrul unor investigații; sau pentru a preveni decesul
            sau vătămarea corporală iminentă a unor persoane. Informațiile pe
            care le primim despre dvs. pot fi accesate, procesate și păstrate pe
            o perioadă mai lungă de timp atunci când fac obiectul unei
            solicitări de natură juridică, al unei investigații guvernamentale
            sau al unor investigații privind posibile încălcări ale termenilor
            și politicilor noastre sau pentru a preveni în alt mod potențiale
            prejudicii.
            <br />
          </code>

          <br />

          <code>
            <h5>
              <b>3. Stocarea informațiilor dvs.:</b>
            </h5>
            Sunteți responsabil în permanență pentru asigurarea caracterului
            secret al parolei dvs. unice și al informațiilor despre cont, precum
            și pentru controlarea accesului la e-mailurile dintre dvs. și
            PinApp.
            <br />
          </code>

          <br />

          <code>
            <h5>
              <b>
                4.Opțiunile pe care le aveți la dispoziție cu privire la
                informațiile dvs.
              </b>
            </h5>
            <br />
            <h5>
              <b>
                Informațiile contului dvs. și setările de confidențialitate ale
                profilului dvs.:
              </b>
            </h5>
            Vă puteți actualiza contul în orice moment conectându-vă și
            modificând setările profilului.
            <br />
          </code>

          <br />

          <code>
            <h5>
              <b>Cât timp păstrăm Conținutul dvs.:</b>
            </h5>
            În urma închiderii sau dezactivării contului dvs., este posibil ca
            PinApp, Companiile sale afiliate sau Furnizorii săi de servicii să
            păstreze informații (inclusiv informațiile dvs. de profil) și
            elemente din Conținutul dvs. pentru o perioadă de timp rezonabilă
            din punct de vedere comercial, în scopuri de backup, arhivare și/sau
            audit.
            <br />
          </code>

          <br />

          <code>
            <h5>
              <b>5. Modificări ale politici de confidențialitate:</b>
            </h5>
            PinApp poate modifica sau actualiza din când în când prezenta
            Politică de confidențialitate; așadar, vă rugăm să o consultați
            periodic. Este posibil să vă comunicăm modificările și actualizările
            și în alte moduri, în funcție de circumstanțe. Utilizarea în
            continuare a PinApp sau a Serviciului după efectuarea unor
            modificări asupra prezentei Politici de confidențialitate reprezintă
            reprezintă acordul dvs. cu privire la aceste modificări.
            <br />
          </code>
        </>
      )} */}
    </>
  )
}
