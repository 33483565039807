import { combineReducers } from 'redux'

import businessesReducer from './businessesReducer'
import challengesReducer from './challengesReducer'
import locationsReducer from './locationsReducer'
import pinsReducer from './pinsReducer'
import prizesReducer from './prizesReducer'
import usersReducer from './usersReducer'

export default initialState =>
  combineReducers({
    pins: pinsReducer,
    users: usersReducer,
    businesses: businessesReducer,
    locations: locationsReducer,
    challenges: challengesReducer,
    prizes: prizesReducer,
  })
