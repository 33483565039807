import './Challenges.scss'

import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  getAllChallengeInfo,
  setCurrentChallenge,
} from '../../actions/challengesActions'
import Challenge from '../Challenge/Challenge'

export default function Challenges(props) {
  const handleSecondPageContent = props.handleSecondPageContent

  const dispatch = useDispatch()
  const [selectedChallengeId, setSelectedChallengeId] = useState()

  const challengeClicked = challenge => {
    setSelectedChallengeId(challenge.ID)
    dispatch(setCurrentChallenge(challenge.ID))
    dispatch(getAllChallengeInfo(challenge.ID))
    handleSecondPageContent(
      'ChallengeDetails',
      selectedChallengeId !== challenge.ID
    )
  }

  const addClicked = () => {
    setSelectedChallengeId('')
    dispatch(setCurrentChallenge(''))
    handleSecondPageContent('AddChallenge')
  }

  return (
    <>
      <div className='challenges-top'>
        <span>Challenges</span>
      </div>

      <div className='challenges-list'>
        {!props?.challenges?.length && (
          <>
            <div className='no-challenge-found'>
              <img src='images/challenges_logo.svg' alt='challenges'></img>

              <p>Add challenges for your customers to make shopping more fun</p>
            </div>
          </>
        )}

        {props?.challenges?.length > 0 &&
          props.challenges.map((challenge, index) => {
            return (
              <Challenge
                key={index}
                challenge={challenge}
                customClickEvent={challengeClicked}
                currentChallengeId={selectedChallengeId}
              />
            )
          })}
      </div>

      <div className='challenges-bottom'>
        <div className='add-btn' onClick={() => addClicked()}>
          + Add
        </div>
      </div>
    </>
  )
}
