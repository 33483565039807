import '../../App.scss'
import './FullScreen.scss'

import React from 'react'

import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy'

export default function FullScreen() {
  const path = window.location.pathname

  const renderPage = () => {
    switch (path) {
      case '/':
        return <></>
      case '/user-privacy-policy':
        return <PrivacyPolicy />
      default:
        return <></>
    }
  }

  return <div id='full-screen'>{renderPage()}</div>
}
