import './ChallengeTask.scss'

import ImageZoom from '@moorecp/react-medium-image-zoom'
import React from 'react'
import { useSelector } from 'react-redux'

import common from '../../common'

export default function ChallengeTask() {
  const selectedChallengeTasks = useSelector(
    state => state.challenges.currentChallengeTasks
  )
  const selectedChallengeTaskIndex = useSelector(
    state => state.challenges.currentChallengeTaskIndex
  )
  const selectedChallengeTask =
    selectedChallengeTasks[selectedChallengeTaskIndex]

  return (
    <>
      <div id='challenge-task-top'>Task</div>

      <div id='challenge-task-wrapper'>
        <div className='page-row'>
          <div className='row-left-column black'>
            {selectedChallengeTask.NoOfSubmits} submits
          </div>
          <div className='row-right-column'>
            {selectedChallengeTask.NoOfApproves} approved
          </div>
        </div>

        <div className='page-row'>
          <div className='row-left-column'>Position:</div>
          <div className='row-right-column'>
            {selectedChallengeTask.Position + 1}
          </div>
        </div>

        <div className='page-row'>
          <div className='row-left-column'>Type:</div>
          <div className='row-right-column'>
            {selectedChallengeTask.ChallengeStepType.Name}
          </div>
        </div>

        {selectedChallengeTask.Title && (
          <div className='page-row'>
            <div className='row-left-column'>Title:</div>
            <div className='row-right-column'>
              {selectedChallengeTask.Title}
            </div>
          </div>
        )}

        {selectedChallengeTask.Description && (
          <div className='page-row'>
            <div className='row-left-column'>Description:</div>
            <div className='row-right-column'>
              {selectedChallengeTask.Description}
            </div>
          </div>
        )}

        {!!selectedChallengeTask.NoOfUsers && (
          <div className='page-row'>
            <div className='row-left-column'>No. of users:</div>
            <div className='row-right-column'>
              {selectedChallengeTask.NoOfUsers}
            </div>
          </div>
        )}

        {selectedChallengeTask.Latitude && (
          <div className='page-row'>
            <div className='row-left-column'>Latitude:</div>
            <div className='row-right-column'>
              {selectedChallengeTask.Latitude}
            </div>
          </div>
        )}

        {selectedChallengeTask.Longitude && (
          <div className='page-row'>
            <div className='row-left-column'>Longitude:</div>
            <div className='row-right-column'>
              {selectedChallengeTask.Longitude}
            </div>
          </div>
        )}

        <div className='page-row'>
          <div className='row-left-column'>Go to business location:</div>
          <div className='row-right-column'>
            {selectedChallengeTask.ShouldGoToBusinessLocation ? 'Yes' : 'No'}
          </div>
        </div>

        {selectedChallengeTask.ImageURL && (
          <div className='page-row'>
            <ImageZoom
              image={{
                src: `${common.imagesBaseUrl}/${selectedChallengeTask.ImageURL}`,
                className: 'challenge-task-image',
              }}
              zoomImage={{
                src: `${common.imagesBaseUrl}/${selectedChallengeTask.ImageURL}`,
              }}
            />
          </div>
        )}
      </div>
    </>
  )
}
