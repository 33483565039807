import axios from 'axios'

import * as actions from '../actionTypes/locationsActionTypes'
import common from '../common'
import safePick from '../utils/safePick'
import { updateShownChallenges } from './challengesActions'

const refreshLocationList = locationList => ({
  type: actions.UPDATE_LIST_OF_LOCATIONS,
  locationList,
})

const getLocationTypes = locationTypes => ({
  type: actions.GET_LOCATION_TYPES,
  locationTypes,
})

const setLocationsError = errorMessage => ({
  type: actions.SET_LOCATIONS_ERROR,
  error: errorMessage || 'Unknown error occured',
})

const deleteLocationAction = locationID => ({
  type: actions.DELETE_LOCATION,
  locationID,
})

export const clearLocationsError = () => ({
  type: actions.CLEAR_LOCATIONS_ERROR,
})

export const setCurrentLocation = ID => ({
  type: actions.SET_CURRENT_LOCATION,
  ID,
})

export const setCurrentLocationOpenHours = openHours => ({
  type: actions.SET_CURRENT_LOCATION_OPEN_HOURS,
  openHours,
})

export const saveLocation =
  (location, selectedFormDataArray, handleSecondPageContent, currentUser) =>
  async (dispatch, getState) => {
    try {
      let imagePosition = 0
      let images = []
      if (location.ID) {
        images = location.Images
        imagePosition = location.Images.length
      }

      const savePhoto = async function (file) {
        const fd = new FormData()
        fd.append('File', file, file.name)
        await axios
          .post(`${common.baseUrl}/BusinessPins/UploadPicture`, fd, {
            headers: {
              Authorization: `${localStorage.getItem(
                'TokenType'
              )} ${localStorage.getItem('AccessToken')}`,
            },
          })
          .then(response => {
            images.push({ URL: response.data, Position: imagePosition })
            ++imagePosition
          })
      }

      for (const file of selectedFormDataArray) {
        await savePhoto(file)
      }

      location.images = images

      const url = location.ID
        ? `${common.baseUrl}/BusinessPins/Update`
        : `${common.baseUrl}/BusinessPins/Add`

      const req = location.ID ? axios.put : axios.post

      await req(url, location, {
        headers: {
          Authorization: `${localStorage.getItem(
            'TokenType'
          )} ${localStorage.getItem('AccessToken')}`,
        },
      }).then(async () => {
        await axios
          .get(`${common.baseUrl}/BusinessPins/GetByUserID/${currentUser.ID}`, {
            headers: {
              Authorization: `${localStorage.getItem(
                'TokenType'
              )} ${localStorage.getItem('AccessToken')}`,
            },
          })
          .then(async response => {
            dispatch(refreshLocationList(response.data))
            dispatch(
              setCurrentLocation(
                location.ID || response.data.find(x => true)?.ID // edited or added
              )
            )
            handleSecondPageContent('LocationDetails')
          })
      })
    } catch (error) {
      const errorMessage = safePick(error, 'response', 'data', 'Errors', [0])
      dispatch(setLocationsError(errorMessage))
    }
  }

export const getLocationTypesAction = () => async (dispatch, getState) => {
  await axios
    .get(`${common.baseUrl}/BusinessTypes/GetAll`, {
      headers: {
        Authorization: `${localStorage.getItem(
          'TokenType'
        )} ${localStorage.getItem('AccessToken')}`,
      },
    })
    .then(async response => {
      dispatch(getLocationTypes(response.data.BusinessTypes))
    })
}

export const updateShownLocations = () => async (dispatch, getState) => {
  const currentUserId = getState().users.currentUser.ID

  await axios
    .get(`${common.baseUrl}/BusinessPins/GetByUserID/${currentUserId}`, {
      headers: {
        Authorization: `${localStorage.getItem(
          'TokenType'
        )} ${localStorage.getItem('AccessToken')}`,
      },
    })
    .then(async response => {
      dispatch(refreshLocationList(response.data))
    })
}

export const deleteLocation =
  (locationID, handleSecondPageContent) => async (dispatch, getState) => {
    await axios
      .delete(`${common.baseUrl}/BusinessPins/Delete/${locationID}`, {
        headers: {
          Authorization: `${localStorage.getItem(
            'TokenType'
          )} ${localStorage.getItem('AccessToken')}`,
        },
      })
      .then(() => {
        dispatch(deleteLocationAction(locationID))
        dispatch(updateShownChallenges())
        handleSecondPageContent('LocationDetails')
      })
  }

export const updateCurrentLocationOpenHours =
  (currentLocationOpenHours, handleThirdPageContent) =>
  async (dispatch, getState) => {
    try {
      dispatch(setCurrentLocationOpenHours(currentLocationOpenHours))
      handleThirdPageContent('')
    } catch (error) {
      const errorMessage = safePick(error, 'response', 'data', 'Errors', [0])
      dispatch(setLocationsError(errorMessage))
    }
  }
