import './RejectModal.scss'

import React from 'react'
import { useState } from 'react'

export default function RejectModal(props) {
  const element = props.element
  const setOpenModal = props.setOpenModal
  const proceedReject = props.proceedReject

  const [rejectionMessage, setRejectionMessage] = useState()

  return (
    <>
      <div id='reject-modal-container'>
        <img src='images/trash_normal.svg' alt='reject'></img>

        <div id='reject-modal-info'>
          <p id='reject-title'>
            Are you sure you want to decline this {element}?
          </p>

          <textarea
            id='rejection-reason'
            placeholder='Your reason'
            onChange={e =>
              setRejectionMessage(e.target.value || '')
            }></textarea>
        </div>

        <div>
          <div
            className='button-modal'
            id='candel-btn-modal'
            onClick={() => setOpenModal(false)}>
            <span>Cancel</span>
          </div>

          <div
            className='button-modal'
            id='proceed-btn-modal'
            onClick={() => proceedReject(rejectionMessage)}>
            <span>I'm sure</span>
          </div>
        </div>
      </div>
    </>
  )
}
