import axios from 'axios'

import * as actions from '../actionTypes/prizesActionTypes'
import common from '../common'
import safePick from '../utils/safePick'

const setPrizesError = errorMessage => ({
  type: actions.SET_PRIZES_ERROR,
  error: errorMessage || 'Unknown error occured',
})

export const clearPrizesError = () => ({
  type: actions.CLEAR_PRIZES_ERROR,
})

export const getPrizeCodeInfo = prizeCode => async (dispatch, getState) => {
  try {
    const prizeCodeInfo = await axios
      .get(`${common.baseUrl}/Challenges/GetPrizeCodeInfo/${prizeCode}`, {
        headers: {
          Authorization: `${localStorage.getItem(
            'TokenType'
          )} ${localStorage.getItem('AccessToken')}`,
        },
      })
      .then(response => response.data)

    return prizeCodeInfo
  } catch (error) {
    const errorMessage = safePick(error, 'response', 'data', 'Errors', [0])
    dispatch(setPrizesError(errorMessage))
  }
}

export const givePrizeToUser =
  (code, challengeId, userId) => async (dispatch, getState) => {
    try {
      await axios.post(
        `${common.baseUrl}/Challenges/GivePrize`,
        { code, challengeId, userId },
        {
          headers: {
            Authorization: `${localStorage.getItem(
              'TokenType'
            )} ${localStorage.getItem('AccessToken')}`,
          },
        }
      )
    } catch (error) {
      const errorMessage = safePick(error, 'response', 'data', 'Errors', [0])
      dispatch(setPrizesError(errorMessage))
    }
  }
