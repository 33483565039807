import './MenuPage.scss'

import React from 'react'
import { useSelector } from 'react-redux'

export default function MenuPage(props) {
  const handleFirstPageContent = props.handleFirstPageContent
  let clickedMenuOption = props.clickedMenuOption

  const currentUser = useSelector(state => state.users.currentUser)

  return (
    <>
      {/* Businesses */}
      {(currentUser._isAdmin || currentUser._isBusinessOwner) && (
        <div
          className={
            clickedMenuOption === 'Businesses'
              ? 'menu-option-selected'
              : 'menu-option'
          }
          onClick={e => {
            handleFirstPageContent('Businesses')
          }}>
          <img src='images/business.svg' alt='' className='logo-image'></img>
          <span>Business</span>
        </div>
      )}

      {/* Locations */}
      {(currentUser._isAdmin || currentUser._isBusinessOwner) && (
        <div
          className={
            clickedMenuOption === 'Locations'
              ? 'menu-option-selected'
              : 'menu-option'
          }
          onClick={e => handleFirstPageContent('Locations')}>
          <img src='images/locations.svg' alt='' className='logo-image'></img>
          <span>Locations</span>
        </div>
      )}

      {/* Challenges */}
      {(currentUser._isAdmin || currentUser._isBusinessOwner) && (
        <div
          className={
            clickedMenuOption === 'Challenges'
              ? 'menu-option-selected'
              : 'menu-option'
          }
          onClick={e => handleFirstPageContent('Challenges')}>
          <img src='images/challenges.svg' alt='' className='logo-image'></img>
          <span>Challenges</span>
        </div>
      )}

      {/* Approvals */}
      {(currentUser._isAdmin || currentUser._isBusinessOwner) && (
        <div
          className={
            clickedMenuOption === 'Approvals'
              ? 'menu-option-selected'
              : 'menu-option'
          }
          onClick={e => handleFirstPageContent('Approvals')}>
          <img src='images/approvals.svg' alt='' className='logo-image'></img>
          <span>Approvals</span>
        </div>
      )}

      {/* Prizes */}
      {(currentUser._isAdmin ||
        currentUser._isBusinessOwner ||
        currentUser._isBusinessManager) && (
        <div
          className={
            clickedMenuOption === 'Prizes'
              ? 'menu-option-selected'
              : 'menu-option'
          }
          onClick={e => handleFirstPageContent('Prizes')}>
          <img src='images/prizes.svg' alt='' className='logo-image'></img>
          <span>Prizes</span>
        </div>
      )}

      {/* <div
        className={
          clickedMenuOption === 'Pins' ? 'menu-option-selected' : 'menu-option'
        }
        onClick={e => handleFirstPageContent('Pins')}>
        <img src='images/pins.svg' alt='' className='logo-image'></img>
        <span>Pins</span>
      </div> */}
    </>
  )
}
