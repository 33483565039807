import './AddBusiness.scss'

import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  clearBusinessesError,
  saveBusiness,
} from '../../actions/businessesActions'
import { getLocationTypesAction } from '../../actions/locationsActions'
import common from '../../common'

export default function AddBusiness(props) {
  const selectedBusiness = props.business
  const handleSecondPageContent = props.handleSecondPageContent

  const businessesState = useSelector(state => state.businesses)

  const [business, setBusiness] = useState('')
  const [cui, setCui] = useState('')
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [administrator, setAdministrator] = useState('')
  const currentUser = useSelector(state => state.users.currentUser)
  const [selectedImage, setSelectedImage] = useState()
  const [selectedImageFormData, setSelectedImageFormData] = useState()

  const inputImageRef = useRef()
  const dispatch = useDispatch()

  if (businessesState.error) {
    alert(businessesState.error)
    dispatch(clearBusinessesError())
  }

  const handleAddImage = () => {
    inputImageRef.current.click()
  }

  const handleImageRemoval = () => {
    setSelectedImage(null)
    setSelectedImageFormData(null)
  }

  const fileSelectedHandler = event => {
    if (event.target.files.length !== 0) {
      const reader = new FileReader()
      reader.onload = () => {
        if (reader.readyState === 2) {
          setSelectedImage(reader.result)
        }
      }
      reader.readAsDataURL(event.target.files[0])
      setSelectedImageFormData(event.target.files[0])
    }
  }

  const handleSaveBusiness = () => {
    if (name && administrator && cui && phoneNumber && address) {
      let editedBusiness = business
        ? {
            // edit
            Id: business.Id,
            name,
            address,
            phoneNumber,
            administrator,
            cui,
            logoUrl: business.LogoUrl,
          }
        : {
            // new
            name,
            address,
            phoneNumber,
            administrator,
            cui,
          }
      if (editedBusiness.Id && !selectedImage) {
        delete editedBusiness.logoUrl
      }
      dispatch(
        saveBusiness(
          editedBusiness,
          selectedImageFormData,
          handleSecondPageContent,
          currentUser
        )
      )
    }
  }

  useEffect(() => {
    dispatch(getLocationTypesAction())
  }, [dispatch])

  useEffect(() => {
    setBusiness(selectedBusiness)
    if (business) {
      setName(business.Name)
      setAdministrator(business.Administrator)
      setAddress(business.Address)
      setPhoneNumber(business.PhoneNumber)
      setCui(business.Cui)
      setSelectedImage(business.LogoUrl)
    }
  }, [selectedBusiness, business])

  return (
    <>
      <div id='add-business-top'>Business Info</div>

      <div id='add-business-body'>
        <div className='browse-logo-wrapper'>
          {!selectedImage && (
            <>
              <div
                className='browse-logo-round-wrapper'
                onClick={handleAddImage}>
                <img
                  id='plus'
                  src='images/plus_logo_business_dark.svg'
                  alt='+'></img>
              </div>

              <div className='browse-logo-icon'>
                <label className='browse-logo-input'>
                  <input
                    ref={inputImageRef}
                    type='file'
                    onChange={fileSelectedHandler}
                    accept='image/*'
                  />
                </label>
                <img src='images/photo_camera-24px.svg' alt='browse icon'></img>
                <p>Logo</p>
              </div>
            </>
          )}

          {selectedImage && (
            <div className='preview-logo-wrapper'>
              <img
                id='preview-logo'
                src={
                  selectedImage.startsWith('data:image')
                    ? selectedImage
                    : `${common.imagesBaseUrl}/${selectedImage}`
                }
                alt='business logo'
              />

              <div id='delete-image-button' onClick={handleImageRemoval}>
                <img
                  id='delete-image'
                  src='images/trash_black.svg'
                  alt='delete'></img>
              </div>
            </div>
          )}
        </div>

        <input
          type='text'
          name='name'
          placeholder='Business name'
          aria-label='Business name'
          className='form-control text-input add-input'
          onChange={e => setName(e.target.value || '')}
          value={name}
          autoComplete='off'
        />

        <input
          type='text'
          name='address'
          placeholder='Business address'
          aria-label='Address'
          className='form-control text-input add-input'
          onChange={e => setAddress(e.target.value || '')}
          value={address}
          autoComplete='off'
        />

        <input
          type='text'
          name='administrator'
          placeholder='Administrator'
          aria-label='Administrator'
          className='form-control text-input add-input'
          onChange={e => setAdministrator(e.target.value || '')}
          value={administrator}
          autoComplete='off'
        />

        <input
          type='text'
          name='cui'
          placeholder='Registration code'
          aria-label='Registration code'
          className='form-control text-input password-input add-input'
          onChange={e => setCui(e.target.value || '')}
          value={cui}
          autoComplete='off'
        />

        <input
          type='text'
          name='phoneNumber'
          placeholder='Phone'
          aria-label='Phone'
          className='form-control text-input password-input add-input'
          onChange={e => setPhoneNumber(e.target.value || '')}
          value={phoneNumber}
          autoComplete='off'
        />
      </div>

      <div id='add-business-bottom'>
        <div
          id='cancel-button'
          onClick={() =>
            handleSecondPageContent(business ? 'BusinessDetails' : '')
          }>
          Cancel
        </div>
        <div
          id={
            name && administrator && cui && phoneNumber && address
              ? 'save-btn-enabled'
              : 'save-btn-disabled'
          }
          onClick={handleSaveBusiness}>
          Save
        </div>
      </div>
    </>
  )
}
