import axios from 'axios'

import * as actions from '../actionTypes/usersActionTypes.js'
import common from '../common'
import safePick from '../utils/safePick'

const setUsersError = errorMessage => ({
  type: actions.SET_USERS_ERROR,
  error: errorMessage || 'Unknown error occured',
})

export const clearUsersError = () => ({
  type: actions.CLEAR_USERS_ERROR,
})

export const authenicate =
  (email, password, stayLoggedIn) => async (dispatch, getState) => {
    try {
      const data = await axios
        .post(`${common.baseUrl}/Accounts/GenerateToken`, {
          email: email,
          password: password,
        })
        .then(response => response.data)

      if (stayLoggedIn) {
        localStorage.setItem('TokenType', data.TokenType)
        localStorage.setItem('AccessToken', data.AccessToken)
      }
      dispatch(currentUserReceived(data.UserLite))
    } catch (error) {
      logOut()
      const errorMessage = safePick(error, 'response', 'data', 'Errors', [0])
      dispatch(setUsersError(errorMessage))
    }
  }

export const getCurrentUser = () => async (dispatch, getState) => {
  try {
    const data = await axios
      .get(`${common.baseUrl}/Accounts/GetByToken`, {
        headers: {
          Authorization: `${localStorage.getItem(
            'TokenType'
          )} ${localStorage.getItem('AccessToken')}`,
        },
      })
      .then(response => response.data)

    dispatch(dispatch(currentUserReceived(data)))
  } catch (error) {
    logOut()
  }
}

const currentUserReceived = currentUser => ({
  type: actions.CURRENT_USER_RECEIVED,
  currentUser,
})

export const getCurrentUserRoles = () => async (dispatch, getState) => {
  try {
    const data = await axios
      .get(
        `${common.baseUrl}/Roles/GetByUserID/${
          getState().users.currentUser.ID
        }`,
        {
          headers: {
            Authorization: `${localStorage.getItem(
              'TokenType'
            )} ${localStorage.getItem('AccessToken')}`,
          },
        }
      )
      .then(response => response.data)

    dispatch(dispatch(currentUserRolesReceived(data)))
  } catch (error) {
    logOut()
  }
}

const currentUserRolesReceived = currentUserRoles => ({
  type: actions.CURRENT_USER_ROLES_RECEIVED,
  currentUserRoles,
})

export function logOut() {
  localStorage.removeItem('TokenType')
  localStorage.removeItem('AccessToken')
}

export const signUp = signUpData => async dispatch => {
  try {
    await axios.post(`${common.baseUrl}/Accounts/Add`, signUpData)
    await dispatch(authenicate(signUpData.email, signUpData.password, true))
    dispatch(getCurrentUserRoles())
  } catch (error) {
    const errorMessage = safePick(error, 'response', 'data', 'Errors', [0])
    dispatch(setUsersError(errorMessage))
  }
}

export const forgotPassword = email => async dispatch => {
  try {
    await axios.post(`${common.baseUrl}/Accounts/ForgotPassword`, {
      email,
    })
    dispatch(setForgotPwEmailSent(true))
  } catch (error) {
    const errorMessage = safePick(error, 'response', 'data', 'Errors', [0])
    dispatch(setUsersError(errorMessage))
  }
}

export const setForgotPwEmailSent = emailSent => ({
  type: actions.FORGOT_PASSWORD_EMAIL_SENT,
  emailSent,
})

export const resetPassword = credentials => async dispatch => {
  try {
    const response = await axios.put(
      `${common.baseUrl}/Accounts/SetNewUserPassword`,
      credentials
    )
    const { status } = response

    if (status === 200) {
      await dispatch(
        authenicate(credentials.email, credentials.newPassword, true)
      )
      dispatch(getCurrentUserRoles())
    }
  } catch (error) {
    const errorMessage = safePick(error, 'response', 'data', 'Errors', [0])
    dispatch(setUsersError(errorMessage))
  }
}

export const sendContactUsEmail = contactData => async dispatch => {
  try {
    await axios.post(`${common.baseUrl}/Accounts/SendContactEmail`, contactData)
    dispatch(setContactUsEmailSent(true))
  } catch (error) {
    const errorMessage = safePick(error, 'response', 'data', 'Errors', [0])
    dispatch(setUsersError(errorMessage))
  }
}

export const setContactUsEmailSent = emailSent => ({
  type: actions.CONTACT_US_EMAIL_SENT,
  emailSent,
})
