import './Businesses.scss'

import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { setCurrentBusiness } from '../../actions/businessesActions'
import Business from '../Business/Business'

export default function Businesses(props) {
  const handleSecondPageContent = props.handleSecondPageContent

  const dispatch = useDispatch()
  const [selectedBusinessId, setSelectedBusinessId] = useState()

  const businessClicked = business => {
    setSelectedBusinessId(business.Id)
    dispatch(setCurrentBusiness(business.Id))
    handleSecondPageContent(
      'BusinessDetails',
      selectedBusinessId !== business.Id
    )
  }

  const addClicked = () => {
    setSelectedBusinessId('')
    dispatch(setCurrentBusiness(''))
    handleSecondPageContent('AddBusiness')
  }

  return (
    <>
      <div className='businesses-top'>
        <span>Business</span>
      </div>

      <div className='businesses-list'>
        {props.businesses[0] &&
          props.businesses.map((business, index) => {
            return (
              <Business
                key={index}
                business={business}
                customClickEvent={businessClicked}
                currentBusinessId={selectedBusinessId}
              />
            )
          })}
        {!props?.businesses?.length && (
          <>
            <div className='no-business-found'>
              <p>Add your business</p>
            </div>
          </>
        )}
      </div>

      <div className='businesses-bottom'>
        <div className='add-btn' onClick={() => addClicked()}>
          + Add
        </div>
      </div>
    </>
  )
}
