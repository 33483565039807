import './Pins.scss'

import React from 'react'

import Pin from '../Pin/Pin'

export default function Pins(props) {
  return (
    <>
      {props.pins &&
        props.pins.map(pin => {
          return <Pin key={pin.ID} pin={pin} />
        })}

      <div id='get-more-pins-message'>
        Browse the map for more!{' '}
        <span role='img' aria-label='joy'>
          😄
        </span>
      </div>
    </>
  )
}
