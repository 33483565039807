import './Business.scss'

import React from 'react'

import common from '../../common'

export default function Business(props) {
  let business = props.business
  let clickEvent = props.customClickEvent
  let currentBusinessId = props.currentBusinessId

  return (
    <div className='business-wrapper'>
      <div
        id={
          business.Id === currentBusinessId
            ? 'row-business-list-selected'
            : 'row-business-list'
        }
        onClick={() => clickEvent(business)}>
        <div id='business-logo-list'>
          <img
            className='business-logo'
            src={
              !business.LogoUrl
                ? 'images/default_business_logo.svg'
                : `${common.imagesBaseUrl}/${business.LogoUrl}`
            }
            alt='logo'></img>
        </div>
        <div id='business-info-list'>
          <div id='business-name-address-wrapper'>
            <div id='business-name-list'>{business.Name}</div>
            <div id='business-address-list'>{business.Address}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
