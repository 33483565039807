import './LocationDetails.scss'

import ImageZoom from '@moorecp/react-medium-image-zoom'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal } from 'react-responsive-modal'

import { deleteLocation } from '../../actions/locationsActions'
import common from '../../common'
import DeleteModal from '../DeleteModal/DeleteModal'

export default function LocationDetails(props) {
  let location = props.location
  const handleSecondPageContent = props.handleSecondPageContent
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false)

  const editClicked = () => {
    handleSecondPageContent('EditLocation')
  }

  const deleteClicked = () => {
    setOpenModal(true)
  }

  const proceedDeleteLocation = () => {
    dispatch(deleteLocation(location.ID, handleSecondPageContent))
    setOpenModal(false)
  }

  const getDayOfWeekName = dayOfWeekNumber => {
    const daysOfWeek = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ]

    return daysOfWeek[dayOfWeekNumber]
  }

  return (
    <>
      <div id='location-details-top'>
        <div id='location-name'>{location.Name}</div>
      </div>

      <div id='location-details-body'>
        <div className='page-row'>
          <div className='row-left-column'>Location Name:</div>
          <div className='row-right-column'>{location.Name}</div>
        </div>

        <div className='page-row'>
          <div className='row-left-column'>Address:</div>
          <div className='row-right-column'>{location.LocationName}</div>
        </div>

        <div className='page-row'>
          <div className='row-left-column'>Business:</div>
          <div className='row-right-column'>{location.Business.Name}</div>
        </div>

        <div className='page-row'>
          <div className='row-left-column'>Location Type:</div>
          <div className='row-right-column'>
            <img
              src={`images/${location.BusinessType.Icon}`}
              className='business-type-icon'
              alt='business type icon'></img>
            <span>{location.BusinessType.Name}</span>
          </div>
        </div>

        <div className='page-row'>
          <div className='row-left-column'>Description:</div>
          <div className='row-right-column'>{location.Description}</div>
        </div>

        <div className='page-row'>
          <div className='row-left-column'>Open hours:</div>
          <div className='open-hours'>
            <div className='open-text'>Open:</div>

            <dv className='open-hours-list'>
              {location.OpenHours?.length > 0 ? (
                location.OpenHours.sort((a, b) =>
                  a.DayOfWeek > b.DayOfWeek ? 1 : -1
                ).map(x => (
                  <div className='open-hours-item'>
                    <span>{getDayOfWeekName(x.DayOfWeek)}:</span>

                    {x.TimeOpening && x.TimeClosing ? (
                      <span>
                        {x.TimeOpening} - {x.TimeClosing}
                      </span>
                    ) : (
                      <span className='closed-text'>closed</span>
                    )}
                  </div>
                ))
              ) : (
                <div>Not available</div>
              )}
            </dv>
          </div>
        </div>

        <div id='location-details-images-wrapper'>
          {location.Images[0] &&
            location.Images.sort((a, b) => a.Position - b.Position).map(
              (imag, index) => {
                return (
                  <ImageZoom
                    key={index}
                    image={{
                      src: `${common.imagesBaseUrl}/${imag.URL}`,
                      className: 'location-details-image',
                    }}
                    zoomImage={{
                      src: `${common.imagesBaseUrl}/${imag.URL}`,
                    }}
                  />
                )
              }
            )}
        </div>
      </div>

      <div id='location-details-bottom'>
        <div id='delete-location-button' onClick={deleteClicked}>
          Delete
        </div>
        <div id='edit-location-button' onClick={editClicked}>
          Edit
        </div>
      </div>

      <Modal open={openModal} onClose={() => setOpenModal(false)} center>
        <DeleteModal
          element='location'
          setOpenModal={setOpenModal}
          proceedDelete={proceedDeleteLocation}
        />
      </Modal>
    </>
  )
}
