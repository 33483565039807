import * as actions from '../actionTypes/prizesActionTypes'

let initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_PRIZES_ERROR:
      return {
        ...state,
        error: action.error,
      }

    case actions.CLEAR_PRIZES_ERROR:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}
