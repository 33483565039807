import React, { useCallback, useEffect, useRef, useState } from 'react'
import ClickAwayListener from 'react-click-away-listener'
import { useDispatch, useSelector } from 'react-redux'
import {
  getChallengeStepTypes,
  updateCurrentChallengeTask,
} from '../../actions/challengesActions'
import common from '../../common'
import CustomSelect from '../CustomSelect/CustomSelect'
import './AddChallengeTask.scss'

export default function AddChallengeTask(props) {
  const handleThirdPageContent = props.handleThirdPageContent

  const dispatch = useDispatch()

  const selectedChallengeTasks = useSelector(
    state => state.challenges.currentChallengeTasks
  )
  const selectedChallengeTaskIndex = useSelector(
    state => state.challenges.currentChallengeTaskIndex
  )
  const selectedChallengeTask =
    selectedChallengeTasks &&
    typeof selectedChallengeTaskIndex == 'number' &&
    selectedChallengeTasks[selectedChallengeTaskIndex]

  const stepTypes = useSelector(state => state.challenges.stepTypes) || []
  // const locations = useSelector(state => state.locations.locations)

  const [showTaskSelectDropdown, setShowTaskSelectDropdown] = useState()
  const [selectedStepId, setSelectedStepId] = useState()
  const [addressName, setAddressName] = useState()
  const [url, setUrl] = useState()
  const [callToAction, setCallToAction] = useState()
  const [latitude, setLatitude] = useState()
  const [longitude, setLongitude] = useState()
  const [selectedImage, setSelectedImage] = useState()
  const [selectedImageFormData, setSelectedImageFormData] = useState()
  const [noOfUsers, setNoOfUsers] = useState(0)
  const [description, setDescription] = useState()
  const [customTitle, setCustomTitle] = useState()
  const [shouldGoToBusinessLocation, setShouldGoToBusinessLocation] = useState()

  // const [showLocationDropdown, setShowLocationDropdown] = useState()
  // const [selectedLocationId, setSelectedLocationId] = useState()

  const placeInputRef = useRef()
  const inputImageRef = useRef()

  const tasksSelectClicked = () => {
    setShowTaskSelectDropdown(!showTaskSelectDropdown)
  }

  const handleAddImage = () => {
    inputImageRef.current.click()
  }

  const fileSelectedHandler = event => {
    if (event.target.files.length !== 0) {
      const reader = new FileReader()
      reader.onload = () => {
        if (reader.readyState === 2) {
          setSelectedImage(reader.result)
        }
      }
      reader.readAsDataURL(event.target.files[0])
      setSelectedImageFormData(event.target.files[0])
    }
  }

  const handleImageRemoval = () => {
    setSelectedImage(null)
    setSelectedImageFormData(null)
  }

  // const locationsDropdownClicked = () => {
  //   setShowLocationDropdown(!showLocationDropdown)
  // }

  const addressSelected = e => {
    if (e.target.value) {
      setAddressName(e.target.value)
    } else {
      setLatitude(null)
      setLongitude(null)
      setAddressName(null)
    }
  }

  const handleChangeNoOfUsers = number => {
    // number = 1 || -1

    if (
      (noOfUsers === 0 && number === -1) ||
      (noOfUsers === 10 && number === 1)
    ) {
      return
    }

    setNoOfUsers(noOfPrizes => noOfPrizes + number)
  }

  const handleGoToBusinessLocation = () => {
    setShouldGoToBusinessLocation(!shouldGoToBusinessLocation)
  }

  const initPlaceAPI = useCallback(() => {
    if (
      selectedChallengeTask &&
      selectedChallengeTask.Latitude &&
      selectedChallengeTask.Longitude
    ) {
      const geocoder = new window.google.maps.Geocoder()
      const latlng = {
        lat: parseFloat(selectedChallengeTask.Latitude),
        lng: parseFloat(selectedChallengeTask.Longitude),
      }

      geocoder.geocode({ location: latlng }).then(response => {
        const addressName = response.results[0].formatted_address
        setAddressName(addressName)
      })
    } else {
      setAddressName('')
    }

    const autocomplete = new window.google.maps.places.Autocomplete(
      placeInputRef.current
    )

    new window.google.maps.event.addListener(
      autocomplete,
      'place_changed',
      function () {
        let place = autocomplete.getPlace()
        setAddressName(place.formatted_address)
        setLatitude(place.geometry.location.lat())
        setLongitude(place.geometry.location.lng())
      }
    )
  }, [selectedChallengeTask])

  const handleSaveChallengeTask = () => {
    if (!canSave()) {
      return
    }

    if (selectedStepId) {
      let editedTask = selectedChallengeTask
        ? {
            // edit
            ID: selectedChallengeTask.ID,
            ChallengeStepType: stepTypes.find(x => x.ID === selectedStepId),
            Description: description,
            ImageURL: selectedImage,
            Latitude: latitude,
            Longitude: longitude,
            NoOfUsers: noOfUsers,
            Title:
              customTitle ||
              stepTypes.find(x => x.ID === selectedStepId)?.Name ||
              '',
            CallToAction: callToAction,
            URL: url,
            ShouldGoToBusinessLocation: shouldGoToBusinessLocation,
          }
        : {
            // new
            ChallengeStepType: stepTypes.find(x => x.ID === selectedStepId),
            Description: description,
            ImageURL: selectedImage,
            Latitude: latitude,
            Longitude: longitude,
            NoOfUsers: noOfUsers,
            Title:
              customTitle ||
              stepTypes.find(x => x.ID === selectedStepId)?.Name ||
              '',
            CallToAction: callToAction,
            URL: url,
            ShouldGoToBusinessLocation: shouldGoToBusinessLocation,
          }

      dispatch(
        updateCurrentChallengeTask(
          editedTask,
          selectedChallengeTaskIndex,
          selectedImageFormData,
          handleThirdPageContent
        )
      )
    }
  }

  const getCurrentStepAction = () =>
    stepTypes.find(x => x.ID === selectedStepId)?.Action

  const canSave = () => {
    if (!selectedStepId) return false
    if (
      getCurrentStepAction() === 'Location' &&
      !latitude &&
      !shouldGoToBusinessLocation
    )
      return false
    if (getCurrentStepAction() === 'InternalShare' && !noOfUsers) return false
    if (getCurrentStepAction() === 'ExternalShare' && !description) return false
    if (getCurrentStepAction() === 'Image' && !description) return false
    if (getCurrentStepAction() === 'Video' && !description) return false
    if (getCurrentStepAction() === 'Text' && !description) return false
    if (getCurrentStepAction() === 'URL' && !url) return false
    if (getCurrentStepAction() === 'URL' && !callToAction) return false
    return true
  }

  useEffect(() => {
    initPlaceAPI()
    if (selectedChallengeTask) {
      setDescription(selectedChallengeTask.Description)
      setLatitude(selectedChallengeTask.Latitude)
      setLongitude(selectedChallengeTask.Longitude)
      setNoOfUsers(selectedChallengeTask.NoOfUsers)
      setSelectedImage(selectedChallengeTask.ImageURL)
      setCustomTitle(selectedChallengeTask.Title)
      setUrl(selectedChallengeTask.URL)
      setCallToAction(selectedChallengeTask.CallToAction)
      setShouldGoToBusinessLocation(
        selectedChallengeTask.ShouldGoToBusinessLocation
      )
    } else {
      setSelectedStepId(null)
      setDescription(null)
      setLatitude(null)
      setLongitude(null)
      setNoOfUsers(null)
      setSelectedImage(null)
      setCustomTitle(null)
      setUrl(null)
      setCallToAction(null)
      setShouldGoToBusinessLocation(true)
    }
  }, [selectedChallengeTask, initPlaceAPI])

  useEffect(() => {
    if (stepTypes.length === 0) {
      dispatch(getChallengeStepTypes())
    }
  }, [dispatch, stepTypes])

  return (
    <>
      <div id='add-challenge-tasks-top'>Add task</div>

      <div id='add-challenge-task-wrapper'>
        <ClickAwayListener onClickAway={() => setShowTaskSelectDropdown(false)}>
          <div
            id='select-task'
            className='form-control text-input'
            onClick={tasksSelectClicked}>
            <CustomSelect
              defaultText='Pick task'
              optionList={stepTypes}
              showOptions={showTaskSelectDropdown}
              setShowOptions={setShowTaskSelectDropdown}
              setSelectedData={setSelectedStepId}
              preselectedData={
                selectedChallengeTask
                  ? selectedChallengeTask.ChallengeStepType
                  : null
              }
            />
            <img
              id='select-arrow-task'
              className='select-arrow'
              alt='select arrow'
              src={
                showTaskSelectDropdown
                  ? 'images/close-select-arrow.svg'
                  : 'images/open-select-arrow.svg'
              }></img>
          </div>
        </ClickAwayListener>

        <input
          style={{
            display: getCurrentStepAction() ? 'block' : 'none', // optional
          }}
          type='text'
          name='title'
          placeholder={
            stepTypes.find(x => x.ID === selectedStepId)?.Name || 'Custom title'
          }
          aria-label='Title'
          className='form-control text-input add-input'
          value={customTitle || ''}
          onChange={e => setCustomTitle(e.target.value || '')}
        />

        <div
          className='checkbox'
          style={{
            display: getCurrentStepAction() === 'Location' ? 'block' : 'none', // mandatory
          }}>
          <input
            type='checkbox'
            id='go-to-business-location'
            name='goToBusinessLocation'
            checked={shouldGoToBusinessLocation}
            onChange={() => handleGoToBusinessLocation()}
          />
          <label
            htmlFor='go-to-business-location'
            id='go-to-business-location-label'
            className='disable-select'>
            <span>Go to business location</span>
          </label>
        </div>

        <input
          style={{
            display: getCurrentStepAction() === 'Location' ? 'block' : 'none', // mandatory
          }}
          disabled={shouldGoToBusinessLocation}
          ref={placeInputRef}
          type='text'
          name='address'
          placeholder='Address'
          aria-label='Address'
          className='form-control text-input add-input'
          onChange={addressSelected}
          value={addressName}
        />

        <input
          style={{
            display: getCurrentStepAction() === 'URL' ? 'block' : 'none', // mandatory
          }}
          type='text'
          name='url'
          placeholder='Link'
          aria-label='URL'
          className='form-control text-input add-input'
          onChange={e => setUrl(e.target.value || '')}
          value={url}
        />

        <input
          style={{
            display: getCurrentStepAction() === 'URL' ? 'block' : 'none', // mandatory
          }}
          type='text'
          name='calltoaction'
          placeholder='Call to action'
          aria-label='Call to action'
          className='form-control text-input add-input'
          onChange={e => setCallToAction(e.target.value || '')}
          value={callToAction}
          maxLength='12'
        />

        {getCurrentStepAction() === 'InternalShare' && (
          <div id='select-no-of-users'>
            <span id='no-of-users-title'>No. of users</span>

            <div id='no-of-users-controls'>
              <button
                onClick={() => handleChangeNoOfUsers(-1)}
                className={noOfUsers === 0 ? 'disabled-button' : ''}>
                -
              </button>
              <span id='no-of-users-display'>{noOfUsers || 0}</span>
              <button
                onClick={() => handleChangeNoOfUsers(1)}
                className={noOfUsers === 10 ? 'disabled-button' : ''}>
                +
              </button>
            </div>
          </div>
        )}

        {(getCurrentStepAction() === 'Location' || // optional
          getCurrentStepAction() === 'InternalShare' || // optional
          getCurrentStepAction() === 'URL' || //optional
          getCurrentStepAction() === 'ExternalShare' || // mandatory
          getCurrentStepAction() === 'Image' || // mandatory
          getCurrentStepAction() === 'Video' || // mandatory
          getCurrentStepAction() === 'Text') && ( // mandatory
          <div className='form-group'>
            <textarea
              id='task-description'
              className='form-control text-multiline-input'
              name='description'
              placeholder='Task description'
              maxLength='100'
              value={description || ''}
              onChange={e => setDescription(e.target.value || '')}></textarea>
            <div id='chars-restriction'>max 100 characters</div>
          </div>
        )}

        {/* Photo */}
        <div className='browse-logo-wrapper'>
          {!selectedImage && (
            <>
              <div
                className='browse-logo-round-wrapper'
                onClick={handleAddImage}>
                <img
                  id='plus'
                  src='images/plus_logo_business_dark.svg'
                  alt='+'></img>
              </div>

              <div className='browse-logo-icon'>
                <label className='browse-logo-input'>
                  <input
                    ref={inputImageRef}
                    type='file'
                    onChange={fileSelectedHandler}
                    accept='image/*'
                  />
                </label>
                <img src='images/photo_camera-24px.svg' alt='browse icon'></img>
                <p>Logo</p>
              </div>
            </>
          )}

          {selectedImage && (
            <div className='preview-logo-wrapper'>
              <img
                id='preview-logo'
                src={
                  selectedImage.startsWith('data:image')
                    ? selectedImage
                    : `${common.imagesBaseUrl}/${selectedImage}`
                }
                alt='business logo'
              />

              <div id='delete-image-button' onClick={handleImageRemoval}>
                <img
                  id='delete-image'
                  src='images/trash_black.svg'
                  alt='delete'></img>
              </div>
            </div>
          )}
        </div>

        {/* List of Business Locations */}
        {/* <ClickAwayListener onClickAway={() => setShowLocationDropdown(false)}>
          <div
            id='select-location'
            className='form-control text-input add-input'
            onClick={locationsDropdownClicked}>
            <CustomSelect
              defaultText='Location'
              optionList={locations[0]}
              showOptions={showLocationDropdown}
              setShowOptions={setShowLocationDropdown}
              setSelectedData={setSelectedLocationId}
              preselectedData={selectedTask ? selectedTask.Location : null}
            />
            <img
              className='select-arrow'
              alt='select arrow'
              src={
                showLocationDropdown
                  ? 'images/close-select-arrow.svg'
                  : 'images/open-select-arrow.svg'
              }></img>
          </div>
        </ClickAwayListener> */}

        {/* Task image */}
        {/* <div className='browse-task-image-wrapper'>
          {!selectedImage && (
            <div>
              <img id='plus' src='images/plus_logo_business.svg' alt='+'></img>
              <div className='browse-image-icon'>
                <label>
                  <input
                    type='file'
                    onChange={fileSelectedHandler}
                    accept='image/*'
                  />
                  <img
                    src='images/photo_camera-24px.svg'
                    alt='browse img'></img>
                  <p>Image</p>
                </label>
              </div>
            </div>
          )}

          {selectedImage && (
            <>
              <img
                id='preview-logo'
                src={
                  selectedImage.startsWith('data:image')
                    ? selectedImage
                    : `${common.imagesBaseUrl}/${selectedImage}`
                }
                alt='task img'></img>
              <div id='delete-image-button'>
                <img
                  id='delete-image'
                  src='images/trash_black.svg'
                  alt='X'
                  onClick={handleImageRemoval}></img>
              </div>
            </>
          )}
        </div> */}
      </div>

      <div id='add-challenge-task-bottom'>
        <div id='cancel-button' onClick={() => handleThirdPageContent('')}>
          Cancel
        </div>
        <div
          id={canSave() ? 'save-btn-enabled' : 'save-btn-disabled'}
          onClick={handleSaveChallengeTask}>
          Save
        </div>
      </div>
    </>
  )
}
