import './ContactUs.scss'

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { clearUsersError, sendContactUsEmail } from '../../actions/usersActions'
import safePick from '../../utils/safePick'

export default function ContactUs() {
  const dispatch = useDispatch()
  const usersState = useSelector(state => state.users)
  const [contactData, setContactData] = useState({})
  const contactUsEmailSent = usersState.contactUsEmailSent

  // error handling
  if (usersState.error) {
    // TODO: show a nice pop-up
    alert(usersState.error)
    dispatch(clearUsersError())
  }

  const contactDataChanged = e => {
    let { name, value } = e.target
    setContactData({ ...contactData, [name]: value })
  }

  const sendEmail = () => {
    if (
      contactData.fullname &&
      contactData.email &&
      contactData.phone &&
      contactData.message
    ) {
      dispatch(
        sendContactUsEmail({
          ...contactData,
          userId: safePick(usersState, 'currentUser', 'ID'),
        })
      )
    } else {
      // TODO: show a nice pop-up
      alert('Please fill in the fields!')
    }
  }

  return (
    <>
      {!contactUsEmailSent ? (
        <>
          <div id='welcome-tabs-wrapper'>
            <h3>Contact</h3>
          </div>

          <input
            type='text'
            name='fullname'
            placeholder='Name'
            aria-label='Name'
            className='form-control text-input'
            onChange={contactDataChanged}
          />
          <input
            type='text'
            name='email'
            placeholder='Email'
            aria-label='Email'
            className='form-control text-input email-input'
            onChange={contactDataChanged}
          />
          <input
            type='text'
            name='phone'
            placeholder='Phone'
            aria-label='Phone'
            className='form-control text-input'
            onChange={contactDataChanged}
          />

          <div className='form-group'>
            <textarea
              className='form-control text-multiline-input contact-message'
              name='message'
              placeholder='Your message'
              onChange={contactDataChanged}></textarea>
          </div>

          <p id='contact-us-email'>
            Or contact us at:&nbsp;
            <span className='active-text'>contact@pinapp.technology</span>
          </p>

          <div id='buttons-wrapper'>
            <button
              type='button'
              className='btn'
              id='btn-active'
              onClick={() => {
                sendEmail()
              }}>
              <b>Send</b>
            </button>
          </div>
        </>
      ) : (
        <div id='email-sent-message'>
          Your message has been sent.
          <br />
          We will get back to you as soon as possible.
          <br />
          <img src='/images/green-checkmark.svg' alt='Email sent' />
        </div>
      )}
    </>
  )
}
